import { Button, Collapse } from 'antd';
import '@/styles/ClientRequestsData.scss';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { TDataSource } from '@/types/dataSource';
import {
  pathBckLogo,
  pathBerekeLogo,
  pathEurasLogo,
  pathForteLogo,
  pathFreedomlogo,
  pathHalykLogo,
  pathKmfLogo,
  shinhanPathLogo
} from '@/constants';
import { findFinancedOfferDate } from '@/services';

export type TClientRequestsDataProps = {
  requests: TDataSource[] | undefined;
  handleCreateNewReq: () => void;
  termDicts: {
    code: number;
    name: string;
    uuid: string;
    brand_uuid?: string | undefined;
    model_uuid?: string | undefined;
    is_active?: boolean | undefined;
    promo_body?: string | undefined;
  }[];
  handleRequestInfo: (request: TDataSource) => void;
};
const { Panel } = Collapse;

const ClientRequestsData = ({
  requests,
  termDicts,
  handleRequestInfo,
  handleCreateNewReq
}: TClientRequestsDataProps) => {
  console.log(pathBerekeLogo);
  // console.log(requests);
  return (
    <div className="ClientRequestsData_root">
      <div className="ClientRequestsData_head">
        <h2>Заявки: {requests?.length}</h2>
        <Button onClick={handleCreateNewReq}>
          <p className="ClientRequestsData_head__create-text">
            Новая заявка <span>+</span>
          </p>
        </Button>
      </div>
      <Collapse
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <DownOutlined
            style={{
              marginTop: '30px',
              width: '24px',
              height: '24px'
            }}
            rotate={isActive ? 180 : 0}
          />
        )}
      >
        {requests?.length &&
          requests.map((dataSource) => {
            return (
              <Panel
                key={dataSource.id}
                header={
                  <div className="Collapse_header">
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2>
                          {dataSource.loan.auto.includes('undefined')
                            ? 'Авто не выбрано'
                            : dataSource.loan.auto}
                        </h2>
                        <span className="separate_dot">&#183;</span>
                        <p>Инициатор: {dataSource.createdUser}</p>
                      </div>
                      {/* Код для точки по середине строки */}

                      <p>
                        {dataSource.loan.dealer}
                        <span className="separate_dot">&#183;</span>
                        {dataSource.loan.amount} ₸
                        <span className="separate_dot">&#183;</span>
                        Создано:{' '}
                        {dayjs(dataSource?.created_at?.date).format(
                          'DD.MM.YYYY'
                        )}
                      </p>
                    </div>
                    {dataSource.status.isFinanced ? (
                      <div
                        style={{
                          padding: '4px 16px',
                          borderRadius: '8px',
                          background: '#0CAF60',
                          width: '90px'
                        }}
                      >
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: '10px',
                            fontWeight: '700',
                            color: 'white'
                          }}
                        >
                          FINANCED
                        </p>
                      </div>
                    ) : dataSource.status.isSelected ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            marginRight: '10px',
                            borderRadius: '50%',
                            border: '1px solid #E9EAEC',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={
                              dataSource.status.selectedBank ===
                              'Freedom Finance'
                                ? pathFreedomlogo
                                : dataSource.status.selectedBank ===
                                  'Евразийский Банк'
                                ? pathEurasLogo
                                : dataSource.status.selectedBank === 'KMF'
                                ? pathKmfLogo
                                : dataSource.status.selectedBank === 'halyk'
                                ? pathHalykLogo
                                : dataSource.status.selectedBank ===
                                    'Банк Центр Кредит' ||
                                  dataSource.status.selectedBank === 'bcc'
                                ? pathBckLogo
                                : dataSource.status.selectedBank ===
                                  'Береке Банк'
                                ? pathBerekeLogo
                                : dataSource.status.selectedBank === 'bereke'
                                ? pathBerekeLogo
                                : dataSource.status.selectedBank ===
                                  'Forte Bank'
                                ? pathForteLogo
                                : dataSource.status.selectedBank === 'forte'
                                ? pathForteLogo
                                : dataSource.status.selectedBank === 'shinhan'
                                ? shinhanPathLogo
                                : ''
                            }
                            style={{ width: '65%', height: '65%' }}
                            alt="logo_bank"
                          />
                        </div>
                        <div>
                          <h2
                            style={{
                              fontSize: '12px',
                              fontWeight: '600',
                              color: '#111827'
                            }}
                          >
                            {dataSource?.status?.selectedBank || ''}
                          </h2>
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              color: '#687588'
                            }}
                          >
                            Банк выбран
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={
                            dataSource.status.approved === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#0CAF60'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.approved || ''}
                          </p>
                        </div>
                        <div
                          style={
                            dataSource.status.approvedAlternatives === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#2F78EE'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.approvedAlternatives || ''}
                          </p>
                        </div>
                        <div
                          style={
                            dataSource.status.expectations === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#FFD023'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: '#111827'
                            }}
                          >
                            {dataSource?.status?.expectations || ''}
                          </p>
                        </div>
                        <div
                          style={
                            dataSource.status.denied === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#E03137'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.denied}
                          </p>
                        </div>
                        <div
                          style={
                            dataSource.status.errors === '0'
                              ? { display: 'none' }
                              : {
                                  marginRight: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  background: '#9321ff'
                                }
                          }
                        >
                          <p
                            style={{
                              fontSize: '10px',
                              fontWeight: '600',
                              color: 'white'
                            }}
                          >
                            {dataSource?.status?.errors}
                          </p>
                        </div>
                        <div
                          style={
                            !dataSource.status.isDraft
                              ? { display: 'none' }
                              : {
                                  padding: '4px 16px',
                                  borderRadius: '8px',
                                  background: 'rgb(17 24 39)',
                                  width: '90px'
                                }
                          }
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '10px',
                              fontWeight: '700',
                              color: 'white'
                            }}
                          >
                            ЧЕРНОВИК
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                }
              >
                <table>
                  <tr className="Table_header">
                    <th>ID заявки</th>
                    <th>Дата создания заявки</th>
                    <th>Марка и модель,год выпуска</th>
                    <th>Стоимость авто</th>
                    <th>Перв. взнос</th>
                    <th>Срок займа</th>
                    <th>Дата финансирования</th>
                  </tr>

                  <tr className="Table_body">
                    <td> # {dataSource.client.code} </td>
                    <td>
                      {dayjs(dataSource?.created_at?.date).format('DD.MM.YYYY')}
                    </td>
                    <td>{dataSource.loan.auto}</td>
                    <td>{dataSource.loan.amount} ₸</td>
                    <td>{dataSource.loan.initialFee} ₸</td>
                    <td>
                      {termDicts.find(
                        (term) => term.code === dataSource.loan.loanTerm
                      )?.name || ''}{' '}
                      мес.
                    </td>
                    <td>{findFinancedOfferDate(dataSource.responses)}</td>
                  </tr>
                </table>

                <Button
                  onClick={() => {
                    handleRequestInfo(dataSource);
                  }}
                >
                  Подробнее
                </Button>
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

export default ClientRequestsData;
