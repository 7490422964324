import './bank-approved-item.scss';

import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

import { useAppDispatch, useAppSelector } from '@/store';
import { setOfferUuid } from '@/store/offerStep.slice';
import { getFormattedBankName } from '@/services';
import { statusColors } from '@/constants';
import { TBankOffers, TBankOffersAlternative } from '@/types/offers';
import { TAltynResponse } from '@/types/altynResponse.type';

import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { formatPrice } from '@/services/formatter';
import Icons from '@/components/Shared/Icons';

const acceptStatusTexts = {
  approved: 'Принять предложение',
  accepted_alternative: 'Принять предложение',
  pre_approved: 'Принять предварительное одобрение'
};

interface IProps {
  bankOffer: TBankOffers;
  handleShowSpecialConditions: () => void;
  onSelectOffer: (bank: TBankOffersAlternative) => void;
  onCancelBankOffer?: (bank: TBankOffersAlternative) => void;
  isHasOffered: boolean;
  isAltyn?: boolean;
  altynResponse?: TAltynResponse;
  isAlternative?: boolean;
  onContinue: () => void;
}

const NewBankApprovedItem: React.FC<IProps> = ({
  bankOffer,
  handleShowSpecialConditions,
  onSelectOffer,
  onCancelBankOffer,
  isHasOffered,
  isAltyn,
  altynResponse,
  isAlternative,
  onContinue
}) => {
  const dispatch = useAppDispatch();

  const { orderApp } = useAppSelector((state) => state.newOrder);

  const [showAlternatives, setShowAlternatives] = useState<boolean>(false);
  const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false);
  const [bankProduct, setBankProduct] = useState<string>('');
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  useEffect(() => {
    const productBankTitle =
      orderApp?.productInfo.product.stocksBonds?.find(
        (prod) => prod.bank_name === bankOffer.name
      )?.label || 'Стандарт';
    setBankProduct(productBankTitle);
  }, []);

  const toggleAlternatives = () => setShowAlternatives(!showAlternatives);
  const handleAccept = () => {
    if (bankOffer.statusType === 'accepted_alternative') {
      onSelectOffer(bankOffer as TBankOffersAlternative);
      return;
    }

    if (altynResponse && isAltyn) {
      dispatch(setOfferUuid(altynResponse?.offerId));
    }
    onSelectOffer(bankOffer as TBankOffersAlternative);
  };

  return (
    <>
      <div
        className={`bank-approved-item ${
          bankOffer.isOffered ? 'bank-approved-item--chosen' : ''
        } offers__card`}
      >
        <div className="bank-approved-item__header">
          <div className="bank-approved-item__img">
            <img src={bankOffer.icon} alt="" />
          </div>
          <div>
            <p className="bank-approved-item__header-title">
              {getFormattedBankName(bankOffer.name)}
            </p>
            <div className="bank-approved-item__status-wrapper">
              <div
                style={{
                  backgroundColor: statusColors[bankOffer.statusType].bgColor,
                  color: statusColors[bankOffer.statusType].textColor
                }}
                className="bank-approved-item__status"
              >
                {bankOffer.initialStatus?.name || 'Одобрено'}
              </div>
            </div>
          </div>
        </div>

        <div className="bank-approved-item__info">
          <div className="bank-approved-item__info-item">
            <p className="bank-approved-item__info-label">
              Ежемесячный платеж:
            </p>
            <b className="bank-approved-item__info-value">
              {isAltyn && altynResponse
                ? formatPrice(altynResponse.monthlyPayment)
                : bankOffer.monthlyPayment}{' '}
              ₸
            </b>
          </div>
          <div className="bank-approved-item__info-item">
            <p className="bank-approved-item__info-label">Процентная ставка:</p>
            <b className="bank-approved-item__info-value bank-approved-item__info-value--red">
              {isAltyn && altynResponse
                ? altynResponse.percentRate
                : bankOffer.rate}
              %
            </b>
          </div>
          <div className="bank-approved-item__info-item">
            <p className="bank-approved-item__info-label">Срок:</p>
            <b className="bank-approved-item__info-value">
              {isAltyn && altynResponse
                ? altynResponse.loanDuration
                : bankOffer.term}{' '}
              месяца
            </b>
          </div>
          <div className="bank-approved-item__info-item">
            <p className="bank-approved-item__info-label">Сумма займа:</p>
            <b className="bank-approved-item__info-value">
              {isAltyn && altynResponse
                ? formatPrice(altynResponse.amount)
                : bankOffer.loanAmount}{' '}
              ₸
            </b>
          </div>
          <div className="bank-approved-item__info-item">
            <p className="bank-approved-item__info-label">
              Первоначальный взнос:
            </p>
            <b className="bank-approved-item__info-value">
              {isAltyn && altynResponse
                ? formatPrice(altynResponse.initialPayment)
                : bankOffer.initialFee}{' '}
              ₸
            </b>
          </div>
          {(bankOffer.bank === 'halyk' ||
            bankOffer.bank === 'bcc' ||
            bankOffer.bank === 'eu_bank' ||
            bankOffer.bank === 'bereke') && (
            <div className="bank-approved-item__info-item">
              <p className="bank-approved-item__info-label">Доп. продукты:</p>
              <b className="bank-approved-item__info-value">КАСКО в подарок</b>
            </div>
          )}
        </div>

        <div className="bank-approved-item__actions">
          {bankOffer.isOffered && bankOffer.statusType !== 'financed' && (
            <button onClick={onContinue} className="bank-approved-item__accept">
              Продолжить
            </button>
          )}
          {bankOffer.reason && !bankOffer.isOffered && isHasOffered && (
            <button
              onClick={() => setShowDescriptionModal(true)}
              className="bank-approved-item__button bank-approved-item__details"
            >
              Доп. информация
              <Icons.InfoFillIcon />
            </button>
          )}
          {!isHasOffered &&
            acceptStatusTexts[
              bankOffer.statusType as keyof typeof acceptStatusTexts
            ] && (
              <button
                onClick={handleAccept}
                className="bank-approved-item__accept"
              >
                {
                  acceptStatusTexts[
                    bankOffer.statusType as keyof typeof acceptStatusTexts
                  ]
                }
              </button>
            )}
        </div>
      </div>

      <CustomModal
        editClass={'description'}
        handleCancel={() => setShowDescriptionModal(false)}
        handleOk={() => setShowDescriptionModal(false)}
        isModalOpen={showDescriptionModal}
        title="" //{bankOffer.bankDescription ? bankOffer.bankDescription : ''}
        footer={[]}
      >
        <p style={{ marginTop: '30px', fontSize: '16px', fontWeight: '600' }}>
          {isHasOffered
            ? 'Есть уже принятое предложение'
            : bankOffer.reason
            ? bankOffer.reason
            : ''}
        </p>
      </CustomModal>

      <CustomModal
        isModalOpen={cancelModal}
        handleOk={() => {
          if (altynResponse && isAltyn) {
            dispatch(setOfferUuid(altynResponse?.offerId));
          }
          onCancelBankOffer?.(bankOffer as TBankOffersAlternative);
          setCancelModal(false);
        }}
        handleCancel={() => setCancelModal(false)}
        title="Вы уверены что хотите отклонить предложения данного банка?"
        editClass={'cancel'}
        footer={[
          <Button key="back" onClick={() => setCancelModal(false)}>
            Нет
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={() => {
              if (altynResponse && isAltyn) {
                dispatch(setOfferUuid(altynResponse?.offerId));
              }
              onCancelBankOffer?.(bankOffer as TBankOffersAlternative);
              setCancelModal(false);
            }}
          >
            Да, отменить
          </Button>
        ]}
      />
    </>
  );
};

export default NewBankApprovedItem;
