import './graphic.scss';

import React, { useEffect, useRef, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

import { formatPrice } from '@/services/formatter';

interface DataInterface {
  name: string;
  value: number;
  color: string;
}

interface IProps {
  title: string;
  data: DataInterface[];
  underText: string;
}

const Graphic: React.FC<IProps> = ({ title, data, underText }) => {
  const [hoveredElement, setHoveredElement] = useState<DataInterface | null>(
    null
  );

  const chartInfo = useRef<HTMLDivElement>(null);
  const chartValue = useRef<HTMLElement>(null);

  useEffect(() => {
    if (chartInfo.current && chartValue.current) {
      let fontSize = 32; // Начальный размер шрифта

      chartValue.current.style.fontSize = `${fontSize}px`;

      // Цикл уменьшает шрифт до тех пор, пока текст не войдет в контейнер
      while (
        chartValue.current.offsetWidth > chartInfo.current.offsetWidth - 32 &&
        fontSize > 10
      ) {
        fontSize -= 1;
        chartValue.current.style.fontSize = `${fontSize}px`;
      }
    }
  }, []);

  const totalAmount = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className="graphic">
      <p className="graphic__title">{title}</p>

      <div className="graphic__chart">
        <PieChart width={276} height={276}>
          <Pie
            data={data}
            innerRadius={105}
            outerRadius={135}
            paddingAngle={2.5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                style={{ outline: 'none', stroke: 'none' }}
                // @ts-ignore
                cornerRadius={6}
                onMouseOverCapture={() => setHoveredElement(entry)}
                onMouseLeave={() => setHoveredElement(null)}
              />
            ))}
          </Pie>
        </PieChart>

        <div ref={chartInfo} className="graphic__chart-info">
          <b ref={chartValue}>{formatPrice(totalAmount)}</b>
          <p>{underText}</p>
        </div>
      </div>

      <div className="graphic__info">
        {data.map((item) => (
          <div key={item.name} className="graphic__info-item">
            <span
              style={{
                backgroundColor: item.color,
                transform:
                  hoveredElement?.name === item.name ? 'scale(1.5)' : undefined
              }}
              className="graphic__item-circle"
            ></span>
            <p className="graphic__item-title">{item.name}</p>
            <b className="graphic__item-value">{formatPrice(item.value)}</b>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Graphic;
