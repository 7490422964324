import React from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { resetRequestId } from '@/store/newOrder.slice';
import { phoneIcon, statusColors } from '@/constants';
import { TClientDataState } from '@/types/state';
import { useNavigate } from 'react-router-dom';
import Icons from '@/components/Shared/Icons';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';
import { Tooltip } from 'antd';

interface IProps {
  clientData: TClientDataState;
  openEditModal: () => void;
  current?: number;
}

const ClientInfo: React.FC<IProps> = ({
  clientData,
  openEditModal,
  current = 0
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loan, orderApp } = useAppSelector((state) => state.newOrder);

  return (
    <div className="client_info">
      <div
        className="client_info__id-card"
        onClick={() => {
          if (!clientData.id) navigate('/auto-cred');
          else navigate(-1);
          dispatch(resetRequestId());
          dispatch(resetReqSuccessIndicator());
        }}
      >
        <button className="client_info__go-back">
          <Icons.BackArrowIcon />
        </button>
        <div>
          {clientData.id && <h2>ID #{clientData.id}</h2>}
          <p
            style={{
              backgroundColor: orderApp?.status
                ? statusColors[orderApp?.status as keyof typeof statusColors]
                    .bgColor
                : '#313A49',
              color:
                statusColors[orderApp?.status as keyof typeof statusColors]
                  .textColor
            }}
            className="client-info__status"
          >
            {orderApp?.initialStatus?.name}
          </p>
        </div>
      </div>

      <div className="client_info__card">
        <div
          style={{
            // @ts-ignore
            '--block-color': '#C8BBFF'
          }}
          className="client_info__block-title"
        >
          Общая информация
        </div>
        <h2
          className={`${
            current === 0 ? 'editable' : ''
          } client_info__normal-text`}
          onClick={openEditModal}
        >
          <Tooltip
            title={`${clientData.lastName} ${clientData.firstName} ${clientData.middleName}`}
          >
            {`${clientData.lastName} ${clientData.firstName} ${clientData.middleName}`}
          </Tooltip>
        </h2>
        <p>ИИН: {clientData.iin}</p>
        {clientData.telNum && (
          <p className="client_info__phone">
            {clientData.telNum}
            <a
              href={`tel:${clientData.telNum.replace(/\(|\)|-|\s/g, '')}`}
              className="client_info__icon"
            >
              <img src={phoneIcon} alt="" />
            </a>
          </p>
        )}
      </div>

      {current !== 0 && (
        <div className="client_info__card">
          <div
            style={{
              // @ts-ignore
              '--block-color': '#FFACA6'
            }}
            className="client_info__block-title"
          >
            Запрошенные данные
          </div>
          <h2>
            <Tooltip title={loan.auto}>{loan.auto}</Tooltip>
            <span className="client_info__icon">
              <Icons.CarIcon />
            </span>
          </h2>
          <p className="client_info__values">
            <b>Стоимость авто:</b>
            <span>{orderApp?.productInfo.product.costCar} ₸</span>
          </p>
          <p className="client_info__values">
            <b>Первоначальный взнос:</b>
            <span>{orderApp?.productInfo.product.initFee} ₸</span>
          </p>
          <p className="client_info__values">
            <b>Запрошенная сумма:</b>
            <span>{orderApp?.productInfo.product.requestAmount} ₸</span>
          </p>
          <p className="client_info__values">
            <b>Срок займа:</b>
            <span>
              {((orderApp?.productInfo.product.loanTerm as number) || 0) * 12}{' '}
              месяцев
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
