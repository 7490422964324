export const DEALERS_DATA = [
  {
    key: 'all',
    label: 'Все дилеры',
    mainInfo: {
      approvalLevel: '85 %',
      averageResponseTime: '8.5 минут',
      averageCarPrice: '7 470 552 ₸',
      averagePVPercents: '18%',
      averageLoanRate: '21.53%',
      popularLoanTerm: '72 месяца'
    },
    banks: [
      {
        name: 'Банк 1',
        requests: 174,
        loanAmount: 1_241_410_482
      },
      {
        name: 'Банк 2',
        requests: 147,
        loanAmount: 1_174_184_844
      },
      {
        name: 'Банк 3',
        requests: 187,
        loanAmount: 1_456_295_786
      },
      {
        name: 'Банк 4',
        requests: 120,
        loanAmount: 947_825_160
      },
      {
        name: 'Банк 5',
        requests: 87,
        loanAmount: 665_928_015
      }
    ]
  },
  {
    key: 'first-dealer',
    label: 'Дилер 1',
    mainInfo: {
      approvalLevel: '83 %',
      averageResponseTime: '7.2 минут',
      averageCarPrice: '7 134 543 ₸',
      averagePVPercents: '23%',
      averageLoanRate: '20.43%',
      popularLoanTerm: '84 месяца'
    },
    banks: [
      {
        name: 'Банк 1',
        requests: 37,
        loanAmount: 264_078_435
      },
      {
        name: 'Банк 2',
        requests: 33,
        loanAmount: 235_529_415
      },
      {
        name: 'Банк 3',
        requests: 25,
        loanAmount: 178_431_375
      },
      {
        name: 'Банк 4',
        requests: 14,
        loanAmount: 99_921_570
      },
      {
        name: 'Банк 5',
        requests: 10,
        loanAmount: 71_372_550
      }
    ]
  }
];

export const BANKS_COLORS = {
  'Банк 1': '#4BA9B8',
  'Банк 2': '#E6BB20',
  'Банк 3': '#DAAB6B',
  'Банк 4': '#85B447',
  'Банк 5': '#2DD4BF'
};

export const topDCTable = [
  {
    name: 'Дилер 1',
    loans: 119,
    loansAmount: 849_010_617,
    averageLoanAmount: 7_134_543,
    approvalLevel: 83
  },
  {
    name: 'Дилер 2',
    loans: 101,
    loansAmount: 806_752_852,
    averageLoanAmount: 7_987_652,
    approvalLevel: 86
  },
  {
    name: 'Дилер 3',
    loans: 87,
    loansAmount: 677_527_986,
    averageLoanAmount: 7_787_678,
    approvalLevel: 79
  },
  {
    name: 'Дилер 4',
    loans: 73,
    loansAmount: 576_593_639,
    averageLoanAmount: 7_898_543,
    approvalLevel: 75
  },
  {
    name: 'Дилер 5',
    loans: 58,
    loansAmount: 443_952_010,
    averageLoanAmount: 7_654_345,
    approvalLevel: 82
  },
  {
    name: 'Дилер 6',
    loans: 52,
    loansAmount: 399_752_236,
    averageLoanAmount: 7_687_543,
    approvalLevel: 91
  },
  {
    name: 'Дилер 7',
    loans: 44,
    loansAmount: 283_042_716,
    averageLoanAmount: 6_432_789,
    approvalLevel: 83
  },
  {
    name: 'Дилер 8',
    loans: 38,
    loansAmount: 265_523_328,
    averageLoanAmount: 6_987_456,
    approvalLevel: 63
  },
  {
    name: 'Дилер 9',
    loans: 25,
    loansAmount: 194_735_800,
    averageLoanAmount: 7_789_432,
    approvalLevel: 69
  },
  {
    name: 'Дилер 10',
    loans: 25,
    loansAmount: 176_293_032,
    averageLoanAmount: 7_345_543,
    approvalLevel: 92
  }
];
