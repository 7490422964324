import './auth.scss';

import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormControlLabel, Typography } from '@mui/material';
import { ButtonAuth } from '../Shared/Button/ButtonAuth';
import CustomCheckboxMui from '../Shared/Checkbox/CustomCheckboxMui';
import styled from 'styled-components';
import { pathCloseXIcon, pathLogoBg, warningIcon } from '@/constants';
import '@fontsource/ibm-plex-sans';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store';
import { login, resetLoginError, toggleLoginError } from '@/store/auth.slice';
import '@/styles/Auth.scss';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import FloatInput from '../Shared/Input/labeled-input/LabeledInput';
import { useNavigate } from 'react-router-dom';

import techDriveLogo from '@/media/TechDrive-logo.png';

interface IFormInput {
  email: number;
  password: string;
  example: string;
}

const StyledInput = styled.div`
  & > span {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    padding: 9px 8px;
    transition: 0.3s;
    outline: none;
    cursor: pointer;
  }

  input:focus + span {
    fill: dodgerBlue;
    outline: none;
  }

  &.inputWithIcon {
    position: relative;
    outline: none;
  }
`;

export const LoginPage = () => {
  const navigate = useNavigate();
  const { loginError, isLoading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState<boolean>(false);
  const [type, setType] = useState('password');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const handleCheckboxChangeRememberMe = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setChecked(event.target.checked);
  const handleLogin = async (email: string, password: string) => {
    dispatch(login({ email, password, isRemember: checked }));
    navigate('/');
  };
  const onSubmit = (data: IFormInput) => alert(JSON.stringify(data));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    dispatch(resetLoginError());
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    dispatch(resetLoginError());
  };

  const handleToggle = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen size="large" />
      <div className="auth">
        <div className="auth__content">
          <div className="auth__logo">
            <img src={techDriveLogo} alt="logo" width={'370px'} />
          </div>
          <h2 className="auth__title">
            <strong>Вход в систему</strong>
          </h2>
          <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
            <FloatInput value={email} label="Email" required={true}>
              <input
                type="email"
                {...register('email', { required: true })}
                style={{
                  padding: '16px',
                  border: '1px solid #E9EAEC',
                  borderRadius: '10px',
                  width: '100%',
                  marginBottom: '20px'
                }}
                value={email}
                onChange={handleChangeEmail}
                placeholder="Введите email"
                id="email"
                name="email"
              />
            </FloatInput>
            {errors?.email?.type === 'required' && (
              <p>Обязательно к заполнению</p>
            )}
            <div className="relative">
              <FloatInput value={password} label="Пароль" required={true}>
                <StyledInput className={'inputWithIcon'}>
                  <input
                    type={type}
                    autoComplete="on"
                    {...register('password', { required: true })}
                    style={{
                      padding: '16px',
                      border: '1px solid #E9EAEC',
                      borderRadius: '10px',
                      width: '100%'
                    }}
                    value={password}
                    onChange={handleChange}
                    placeholder="Введите пароль"
                    id="passwd"
                    name="password"
                  />
                  <span
                    className="flex justify-around items-center absolute mr-10"
                    onClick={handleToggle}
                  >
                    {type === 'password' ? (
                      <EyeOutlined
                        style={{ fontSize: '20px', color: '#111827' }}
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        style={{ fontSize: '20px', color: '#111827' }}
                      />
                    )}
                  </span>
                </StyledInput>
              </FloatInput>
            </div>
            {errors?.password?.type === 'required' && (
              <p>Обязательно к заполнению</p>
            )}
            <div style={{ marginBottom: '20px' }}>
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <CustomCheckboxMui
                    checked={checked}
                    onChange={handleCheckboxChangeRememberMe}
                  />
                }
                label={
                  <span
                    style={{
                      userSelect: 'none',
                      fontSize: '12px',
                      marginLeft: '15px'
                    }}
                  >
                    Запомнить меня
                  </span>
                }
              />
            </div>
            {loginError ? (
              <div className="auth_error">
                <div>
                  <img src={warningIcon} alt="warn" />
                  <p>Неверный email или пароль</p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(toggleLoginError());
                  }}
                >
                  <img src={pathCloseXIcon} alt="close" />
                </button>
              </div>
            ) : (
              ''
            )}
            <ButtonAuth
              title={'Войти'}
              disabled={email && password ? false : true}
              onClick={() => handleLogin(email, password)}
            />
          </form>

          <div className="auth__rights">
            <Typography
              align="center"
              fontWeight="500"
              fontSize="14px"
              fontFamily="IBM Plex Sans"
              color="#A0AEC0"
            >
              © 2024 TechDrive All rights reserved
            </Typography>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${pathLogoBg})` }}
          className="auth__banner"
        >
          <div className="auth__banner-info">
            <p className="auth__banner-title">Добро пожаловать!</p>
            <p className="auth__banner-description">
              Войдите в систему, чтобы начать работу
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
