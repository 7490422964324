import '@/styles/NewOrder.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';
import { validateNumberInputValue } from '@/services';
import { formatPhoneNumber } from '@/services/formatter';
import { TClientDataState, TClientDataStateBool } from '@/types/state';

import { notification, Steps } from 'antd';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import BreadCrumbs from '@/components/Shared/BreadCrumbs';

import ClientInfo from './ClientInfo';
import { OrderStep } from './OrderStep/OrderStep';
import useFilters from '@/hooks/useFilters';
import { resetRequestId } from '@/store/newOrder.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';

const items = [
  {
    key: 'order',
    icon: <span>1</span>,
    title: 'Заявка'
  },
  {
    key: 'offer',
    icon: <span>2</span>,
    title: 'Предложения'
  },
  {
    key: 'financing',
    icon: <span>3</span>,
    title: 'Финансирование'
  }
];

export const NewOrder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { clientInfo, orderApp, requestId } = useAppSelector(
    (state) => state.newOrder
  );
  const { selectedBank } = useAppSelector((state) => state.financingStep);

  const { filters, handleFiltersChange } = useFilters<{
    step: number;
    tab: number;
  }>([
    { key: 'step', defaultValue: 1 },
    { key: 'tab', defaultValue: 1 }
  ]);
  const current = filters.tab - 1;

  const [editModal, showEditModal] = useState<boolean>(false);
  const [reportModal, showReportModal] = useState<boolean>(false);
  const [clientData, setClientData] = useState<TClientDataState>({
    firstName: clientInfo?.fullName[1] || '',
    middleName: clientInfo?.fullName[2] || '',
    lastName: clientInfo?.fullName[0] || '',
    iin: clientInfo?.iin || '',
    telNum: formatPhoneNumber(clientInfo?.telNum || ''),
    id: clientInfo?.id || '',
    errorTitle: '',
    comments: ''
  });

  const [isFocused, setIsFocused] = useState<TClientDataStateBool>({
    firstName: true,
    middleName: true,
    lastName: true,
    iin: true,
    id: false,
    errorTitle: false,
    comments: false
  });

  useEffect(() => {
    if (current !== 0) {
      handleFiltersChange({ tab: 1 });
    }
  }, [current]);

  useEffect(() => {
    if (!requestId) {
      navigate('/auto-credit');
    }
  }, [navigate, requestId]);

  const handleNextStep = (requestId: string) => {
    navigate(`/auto-credit/${requestId}?tab=${filters.tab + 1}`);
  };

  const handleEditModal = () => {
    showEditModal(false);
  };

  const onChangeInputHandler = (val: string, label: string, type: string) => {
    if (label === 'iin') val = validateNumberInputValue(val, 12);
    setIsFocused((prevState) => ({
      ...prevState,
      [label]: val !== ''
    }));
    setClientData((prevState) => ({ ...prevState, [label]: val }));
  };

  const openEditModal = () => {
    if (orderApp === null || orderApp?.status === 'draft') {
      if (current !== 0) return;
      setIsFocused((prevState) => ({
        ...prevState,
        firstName: true,
        middleName: true,
        lastName: true
      }));
      showEditModal(true);
    }
  };

  const handleStepCurrentChange = (val: number) => {
    if (!selectedBank && orderApp && orderApp?.status && val === 2) {
      notification.warning({
        message: 'выберите предложение банка',
        description:
          'Чтобы перейти на данный этап, вам необходимо выбрать предложение банка',
        duration: 5
      });
    }
  };

  if (!requestId) return <></>;

  return (
    <div className="NewOrder">
      <CustomModal
        isModalOpen={editModal}
        handleOk={handleEditModal}
        handleCancel={() => showEditModal(false)}
        title={'Введите ФИО'}
        editClass={'editFullName'}
      >
        <>
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.lastName}
            value={clientData.lastName}
            label={'lastName'}
            type={''}
            labelTitle={'Фамилия'}
          />

          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.firstName}
            value={clientData.firstName}
            label={'firstName'}
            type={''}
            labelTitle={'Имя'}
          />
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.middleName}
            value={clientData.middleName}
            label={'middleName'}
            type={''}
            labelTitle={'Отчество'}
          />
        </>
      </CustomModal>
      <CustomModal
        isModalOpen={reportModal}
        handleOk={() => showReportModal(false)}
        handleCancel={() => showReportModal(false)}
        title={'Сообщить о проблеме'}
        editClass={'reportError'}
      >
        <>
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.id}
            value={clientData.id}
            label={'id'}
            type={''}
            labelTitle={'ID заявки'}
          />
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.errorTitle}
            value={clientData.errorTitle}
            label={'errorTitle'}
            type={''}
            labelTitle={'Тема'}
          />
          <CustomInput
            onChangeInputHandler={onChangeInputHandler}
            isFocused={isFocused.comments}
            value={clientData.comments}
            label={'comments'}
            type={''}
            labelTitle={'Добавьте комментарии'}
          />
        </>
      </CustomModal>

      <div className="NewOrder__bread-crumbs">
        <BreadCrumbs
          links={[
            { text: 'Главная', href: '/' },
            {
              text: 'Автокредитование',
              href: '/auto-credit',
              onClick: () => {
                dispatch(resetRequestId());
                dispatch(resetReqSuccessIndicator());
              }
            }
          ]}
          current="Создание"
        />
      </div>

      <div className="NewOrder__grid">
        <ClientInfo clientData={clientData} openEditModal={openEditModal} />

        <div className="container">
          <div className="wrapper">
            <Steps
              className="order_steps"
              current={current}
              items={items}
              onChange={(val) => handleStepCurrentChange(val)}
            />
          </div>
          <div className="content">
            {current === 0 && (
              <OrderStep
                createRequest={handleNextStep}
                clientInfo={clientData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
