import { ColumnsType } from 'antd/es/table';

import { TopDCTableInterface } from './TopFCTable';
import { formatPrice } from '@/services/formatter';

export const topFCColumns: ColumnsType<TopDCTableInterface> = [
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, record) => {
      return (
        <h2 style={{ fontSize: '12px', fontWeight: '600', color: '#111827' }}>
          {record?.name || ''}
        </h2>
      );
    }
  },
  {
    title: 'Кредиты',
    dataIndex: 'loans',
    key: 'loans',
    width: 100,
    sorter: (a, b) => Number(a.loans) - Number(b.loans),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {formatPrice(record?.loans) || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Сумма кредитов',
    dataIndex: 'loansAmount',
    key: 'loansAmount',
    width: 150,
    sorter: (a, b) => Number(a.loansAmount) - Number(b.loansAmount),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {formatPrice(record?.loansAmount)}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Средняя сумма кредита',
    dataIndex: 'averageLoanAmount',
    key: 'averageLoanAmount',
    width: 150,
    sorter: (a, b) => Number(a.averageLoanAmount) - Number(b.averageLoanAmount),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {formatPrice(record?.averageLoanAmount) || ''}
          </p>
        </div>
      );
    }
  },
  {
    title: 'Уровень одобрения ',
    dataIndex: 'approvalLevel',
    key: 'approvalLevel',
    width: 100,
    sorter: (a, b) => Number(a.approvalLevel) - Number(b.approvalLevel),
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <p
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#111827',
              lineHeight: '160%'
            }}
          >
            {`${record?.approvalLevel.toString().replace(/\./, ',')} %`}
          </p>
        </div>
      );
    }
  }
];
