import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ProductItem } from './ProductItem';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import '@/styles/ProductForm.scss';
import { PlusOutlined } from '@ant-design/icons';
import { TAddProducts } from '@/types/productItem';
import { findUniqueValuesOfArray } from '@/services';
import { pathCloseXIcon } from '@/constants';
import { TStockBondsState } from '@/types/state';

type TProductsFormProps = {
  onClose: Dispatch<SetStateAction<string>>;
  open: string;
  addProducts: TAddProducts[] | [];
  additionalChecked: boolean;
  additionalCheckedHandler: (isChecked: boolean) => void;
  handleAddProductsChange: (id: string) => void;
  handleChangeProdInsurance: (data: {
    organisationId: string;
    addProductId: string;
  }) => void;
  handleChangeAddProductLoanPeriod: (data: {
    value: string;
    id: string;
  }) => void;
  stocksBonds: TStockBondsState;
};
export const ProductsForm = ({
  onClose,
  open,
  addProducts,
  additionalChecked,
  additionalCheckedHandler,
  handleAddProductsChange,
  handleChangeAddProductLoanPeriod,
  handleChangeProdInsurance,
  stocksBonds
}: TProductsFormProps) => {
  const [checked, setChecked] = useState<boolean>(additionalChecked);
  const [showMinorProducts, setShowMinorProducts] = useState<boolean>(false);
  const [sortedAddProducts, setSortedAddProducts] = useState<
    {
      title: string;
      addProducts: TAddProducts[];
    }[]
  >([]);
  // const [globalTermWatcher, setGlobalTermWatcher] = useState<string | null>(
  //   null
  // );
  //
  // const {
  //   dict: { dicts }
  // } = useAppSelector((state) => state);

  const toggleChecked = () => setChecked(!checked);

  const onCloseHandler = () => {
    onClose('init');
  };

  useEffect(() => {
    const uniqueProdTitles = addProducts
      .map((prod) => prod.bank_name)
      .filter(findUniqueValuesOfArray);
    const finalArrayHandler = uniqueProdTitles
      .map((uniqueTitle) => {
        const productsArray = [...addProducts].filter(
          (prod) => prod.bank_name === uniqueTitle
        );
        return {
          title: uniqueTitle === '' ? 'no_bank' : uniqueTitle,
          addProducts: productsArray
        };
      })
      .sort((a, b) => {
        if (a.title.includes('no_bank') && !b.title.includes('no_bank')) {
          return 1;
        }
        if (!a.title.includes('no_bank') && b.title.includes('no_bank')) {
          return -1;
        }
        return 0;
      });
    setSortedAddProducts(finalArrayHandler);
  }, [addProducts]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => {
        setShowMinorProducts(!showMinorProducts);
        // const smsData = addProducts.find(
        //   (product) => product.label === 'СМС-информирование'
        // );
        // smsData && handleAddProductsChange(smsData.id);
      },
      label: (
        <div>
          <p>СМС-информирование</p>
        </div>
      )
    }
  ];

  const onClickForm = (
    e: React.MouseEvent<HTMLFormElement, MouseEvent>
  ): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={`ProductsForm ${open}`} onClick={onCloseHandler}>
      <form onClick={onClickForm}>
        <h2 style={{ marginBottom: '20px' }}>Дополнительные продукты</h2>
        <button className="close_btn" onClick={onCloseHandler}>
          <img src={pathCloseXIcon} alt="close_icon" />
        </button>
        <div className="add_new_product">
          <div>
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              arrow={{ pointAtCenter: false }}
            >
              <p>Добавить продукт</p>
            </Dropdown>
            <PlusOutlined />
          </div>
        </div>
        {sortedAddProducts
          ?.filter((p) => p.title !== 'sapa' && p.title !== 'kmf')
          .map((p, i) => (
            <ProductItem
              stocksBonds={stocksBonds}
              key={p.title}
              showMinorProducts={showMinorProducts}
              handleStockBoundChange={handleAddProductsChange}
              handleChangeProdInsurance={handleChangeProdInsurance}
              handleChangeAddProductLoanPeriod={
                handleChangeAddProductLoanPeriod
              }
              sortedProductInfo={p}
            />
          ))}
        <div className="checkbox_root">
          <Checkbox checked={checked} onClick={toggleChecked}>
            <p className="checkbox_root_paragraph" id="customCheckbox">
              Согласен
            </p>
          </Checkbox>
          <div className="checkbox_root_buttons">
            <button className="cancel_button" onClick={onCloseHandler}>
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                additionalCheckedHandler(checked);
                onCloseHandler();
              }}
              className="save_button"
              style={!checked ? { background: '#1F9AFF82' } : {}}
              disabled={!checked}
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
