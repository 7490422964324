import './main-page.scss';

import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Select } from 'antd';

import Icons from '@/components/Shared/Icons';

import Graphic from './ClientMain/Graphic';
import TopDCTable from './ClientMain/TopFCTable/TopFCTable';
import RequestInfo from './ClientMain/request-info';
import { BANKS_COLORS, DEALERS_DATA, topDCTable } from './mock';

const { RangePicker } = DatePicker;

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

const FORMAT = 'D MMM YYYY';

const options = DEALERS_DATA.map((item) => ({
  value: item.key,
  label: item.label
}));

const MainPage = () => {
  const [dealer, setDealer] = useState(options[0].value);
  const [datesValues, setDatesValues] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: dayjs(new Date()).startOf('month'),
    endDate: dayjs(new Date()).startOf('month').add(1, 'month')
  });

  const dealerInfo = useMemo(() => {
    return DEALERS_DATA.find((item) => item.key === dealer)!;
  }, [dealer]);

  const handleRangePickerChange = (value: RangeValue<Dayjs>) => {
    if (!value) {
      setDatesValues({
        startDate: null,
        endDate: null
      });
      return;
    }

    if (!value[0] || !value[1]) return;

    const startDate = dayjs(value[0]);
    const endDate = dayjs(value[1]);
    setDatesValues({ startDate, endDate });
  };

  return (
    <div className="main-page">
      <div className="main-page__filters-card">
        <h3>Сводная информация по заявкам</h3>

        <div className="main-page__filters">
          <Select
            onChange={setDealer}
            value={dealer}
            options={options}
            className="main-page__filters-item main-page__select"
          />

          <RangePicker
            placeholder={['Начало', 'Конец']}
            value={[datesValues.startDate, datesValues.endDate]}
            format={FORMAT}
            onChange={handleRangePickerChange}
            className="main-page__filters-item main-page__date-filter"
          />
        </div>

        <div className="main-page__request-info">
          <div className="main-page__request-item">
            <RequestInfo
              icon={<Icons.BriefcaseIcon />}
              title={dealerInfo.mainInfo.approvalLevel}
              description="Уровень одобрения"
            />
            <RequestInfo
              icon={<Icons.TimerIcon />}
              title={dealerInfo.mainInfo.averageResponseTime}
              description="Среднее время ответа банков"
            />
          </div>
          <div className="main-page__separator"></div>
          <div className="main-page__request-item">
            <RequestInfo
              icon={<Icons.CarIcon />}
              title={dealerInfo.mainInfo.averageCarPrice}
              description="Средняя сумма кредита"
            />
            <RequestInfo
              icon={<Icons.DollarCircleIcon />}
              title={dealerInfo.mainInfo.averagePVPercents}
              description="Средний процент ПВ"
            />
          </div>
          <div className="main-page__separator"></div>
          <div className="main-page__request-item">
            <RequestInfo
              icon={<Icons.ChartIcon />}
              title={dealerInfo.mainInfo.averageLoanRate}
              description="Средняя ставка по кредиту"
            />
            <RequestInfo
              icon={<Icons.CalendarIcon />}
              title={dealerInfo.mainInfo.popularLoanTerm}
              description="Популярный срок кредита"
            />
          </div>
        </div>
      </div>

      <div className="main-page__graphics">
        <Graphic
          title="Количество кредитов"
          underText=""
          data={dealerInfo.banks.map((item) => ({
            name: item.name,
            value: item.requests,
            color: BANKS_COLORS[item.name as keyof typeof BANKS_COLORS]
          }))}
        />
        <Graphic
          title="Сумма кредитов"
          underText=""
          data={dealerInfo.banks.map((item) => ({
            name: item.name,
            value: item.loanAmount,
            color: BANKS_COLORS[item.name as keyof typeof BANKS_COLORS]
          }))}
        />
      </div>

      {dealer === 'all' && (
        <div className="main-page__table-card">
          <TopDCTable dcDataSource={topDCTable} />
        </div>
      )}
    </div>
  );
};

export default MainPage;
