import React, { useEffect, useRef, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import './create-app-dialog-form.css';
import { useAppSelector } from '@/store';
import CustomButtonMui from '@/components/Shared/Button/CustomButtonMui';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: '#FFFFFF',
  borderRadius: '16px',
  padding: '32px',
  fontFamily: 'IBM Plex Sans',
  p: 4
};
const squareStyle = {
  height: '56px',
  width: '59px',
  padding: '16x',
  //border: '1px solid #E9EAEC', //#E9EAEC
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '500',
  fontSize: '25px',
  color: '#111827'
};

const backDropStyles: any = {
  position: 'fixed',
  display: 'flex',
  // webkitAlignItems: 'center',
  // webkitBoxAlign: 'center',
  msFlexAlign: 'center',
  alignItems: 'center',
  zIndex: '101',
  webkitBoxPack: 'center',
  msFlexPack: 'center',
  // webkitJUustifyContent: 'center',
  justifyContent: 'center',
  right: '0',
  bottom: '0',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  webkitTapHighlightColor: 'transparent'
};

type TTransitionsModalProps = {
  openDialog: boolean;
  onClose: () => void;
  step: number;
  checkCode: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  code: {
    firstSquare: string;
    secondSquare: string;
    thirdSquare: string;
    fourthSquare: string;
  };
  setCode: React.Dispatch<
    React.SetStateAction<{
      firstSquare: string;
      secondSquare: string;
      thirdSquare: string;
      fourthSquare: string;
    }>
  >;
  retryOTP: () => void;
};

export default function TransitionsModal({
  openDialog,
  onClose,
  step,
  checkCode,
  code,
  setCode,
  retryOTP
}: TTransitionsModalProps) {
  const { otpValid } = useAppSelector((state) => state.orderStep);

  const [overwatchValue, setOverwatchValue] = useState<number>(59);
  const firstSquareRef = useRef<HTMLInputElement>(null);
  const secondSquareRef = useRef<HTMLInputElement>(null);
  const thirdSquareRef = useRef<HTMLInputElement>(null);
  const fourthSquareRef = useRef<HTMLInputElement>(null);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      if (openDialog && overwatchValue > 0) {
        setOverwatchValue((prevState) => prevState - 1);
      } else {
        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
        }
      }
    }, 1000);

    // Очищаем интервал при размонтировании компонента или изменении зависимостей
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [openDialog, overwatchValue]);

  useEffect(() => {
    if (openDialog) firstSquareRef.current?.focus();
  }, [openDialog]);

  return (
    <div style={openDialog ? backDropStyles : {}}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disableEscapeKeyDown={true}
        // disableBackdropClick={true}
        hideBackdrop={true}
        open={openDialog}
        keepMounted
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={openDialog}>
          <Box sx={style}>
            <IconButton
              onClick={onClose}
              style={{
                height: '32px',
                width: '32px',
                top: '16px',
                right: '16px',
                position: 'absolute'
              }}
            >
              <CloseIcon style={{ height: '32px', width: '32px' }} />
            </IconButton>
            <Stack spacing={3}>
              {step === 1 && (
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <div style={squareStyle}>
                      <input
                        className="verify-input-field"
                        type="number"
                        maxLength={1}
                        ref={firstSquareRef}
                        style={{
                          ...squareStyle,
                          textAlign: 'center',
                          border: code.firstSquare
                            ? '2px solid black'
                            : '1.5px solid  #E9EAEC'
                        }}
                        value={code?.firstSquare}
                        autoFocus={true}
                        onChange={(event) => {
                          setCode((prev: any) => ({
                            ...prev,
                            firstSquare: event.target.value.slice(0, 1)
                          }));
                          if (event.target.value.length === 1) {
                            secondSquareRef.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <div style={squareStyle}>
                      <input
                        // style={{
                        //   borderColor: code.secondSquare ? 'black' : '#E9EAEC'
                        // }}
                        className="verify-input-field"
                        type="number"
                        maxLength={1}
                        ref={secondSquareRef}
                        style={{
                          ...squareStyle,
                          textAlign: 'center',
                          border: code.secondSquare
                            ? '2px solid black'
                            : '1.5px solid  #E9EAEC'
                        }}
                        value={code?.secondSquare}
                        onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                            setCode((prev) => ({
                              ...prev,
                              secondSquare: ''
                            }));
                            firstSquareRef.current?.focus();
                          }
                        }}
                        onChange={(event: any) => {
                          setCode((prev) => ({
                            ...prev,
                            secondSquare: event.target.value.slice(0, 1)
                          }));
                          if (event.target.value.length === 1) {
                            thirdSquareRef.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <div style={squareStyle}>
                      <input
                        className="verify-input-field"
                        type="number"
                        // maxLength={1}
                        ref={thirdSquareRef}
                        style={{
                          ...squareStyle,
                          textAlign: 'center',
                          border: code.thirdSquare
                            ? '2px solid black'
                            : '1.5px solid  #E9EAEC'
                        }}
                        value={code?.thirdSquare}
                        onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                            setCode((prev: any) => ({
                              ...prev,
                              thirdSquare: ''
                            }));
                            secondSquareRef.current?.focus();
                          }
                        }}
                        onChange={(event) => {
                          setCode((prev: any) => ({
                            ...prev,
                            thirdSquare: event.target.value.slice(0, 1)
                          }));
                          if (event.target.value.length === 1) {
                            fourthSquareRef.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <input
                      className="verify-input-field"
                      type="number"
                      maxLength={1}
                      ref={fourthSquareRef}
                      style={{
                        ...squareStyle,
                        textAlign: 'center',
                        border: code.fourthSquare
                          ? '2px solid black'
                          : '1.5px solid  #E9EAEC'
                      }}
                      value={code?.fourthSquare}
                      onChange={(event) => {
                        setCode((prev: any) => ({
                          ...prev,
                          fourthSquare: event.target.value.slice(0, 1)
                        }));
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Backspace') {
                          setCode((prev: any) => ({
                            ...prev,
                            fourthSquare: ''
                          }));
                          thirdSquareRef.current?.focus();
                        }
                      }}
                    />
                  </Stack>
                  {otpValid === false && (
                    <Stack
                      direction="row"
                      spacing={0.5}
                      justifyContent="center"
                    >
                      <ErrorOutlineIcon
                        style={{ fontSize: '18px', color: '#E03137' }}
                      />
                      <Typography
                        fontWeight="400"
                        fontSize="14px"
                        color="#E03137"
                        height="20px"
                        align="center"
                      >
                        Введенный код неправильный
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}

              {step === 1 && (
                <Typography
                  fontWeight="400"
                  fontSize="14px"
                  color="#687588"
                  height="20px"
                  align="center"
                >
                  Отправить код повторно через 00:
                  {overwatchValue > 9 ? overwatchValue : `0${overwatchValue}`}
                </Typography>
              )}
              {step === 1 && checkCode && (
                <Typography
                  fontWeight="400"
                  fontSize="14px"
                  color="#111827"
                  height="22px"
                  align="center"
                  style={{
                    textDecoration: 'underline',
                    textUnderlineOffset: '3px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    overwatchValue === 0 && retryOTP();
                  }}
                >
                  Отправить код еще раз
                </Typography>
              )}

              {step === 3 && checkCode && (
                <Stack spacing={1} justifyContent="center" alignItems="center">
                  <CircularProgress />
                  <Typography
                    fontWeight="400"
                    fontSize="14px"
                    color="#687588"
                    height="20px"
                    align="center"
                  >
                    Подождите...
                  </Typography>
                </Stack>
              )}
              {step === 1 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <CustomButtonMui
                    onClick={onClose}
                    title="Отмена"
                    width="100%"
                    action="cancel"
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
