import './request-info.scss';

interface IProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

const RequestInfo: React.FC<IProps> = ({ icon, title, description }) => {
  return (
    <div className="request-info">
      <span className="request-info__icon">{icon}</span>

      <div>
        <p className="request-info__title">{title}</p>
        <p className="request-info__description">{description}</p>
      </div>
    </div>
  );
};

export default RequestInfo;
