import { Table } from 'antd';

import { topFCColumns } from './topFC.data';

export interface TopDCTableInterface {
  name: string;
  loans: number;
  loansAmount: number;
  averageLoanAmount: number;
  approvalLevel: number;
}

export type TPropsTopFCTable = {
  dcDataSource: TopDCTableInterface[];
};

const TopDCTable = ({ dcDataSource }: TPropsTopFCTable) => {
  return (
    <div className="TopFCTable">
      <div className="TopFCTable_header_root">
        <div>
          <h2 className="main-page__table-title">ТОП-10 ДЦ</h2>
          <p className="main-page__table-description">
            Рейтинг ДЦ по выданным кредитам
          </p>
        </div>
      </div>

      <div className="table main-page__table">
        <Table
          size="large"
          dataSource={dcDataSource}
          columns={topFCColumns}
          rowKey={(record) => record?.name || ''}
          pagination={
            dcDataSource.length > 10
              ? {
                  position: ['bottomLeft'],
                  pageSize: 10
                }
              : false
          }
        />
      </div>
    </div>
  );
};

export default TopDCTable;
