export const FiltersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.27944 16.5929C3.27944 16.1787 3.61523 15.8429 4.02944 15.8429H10.3302C10.7444 15.8429 11.0802 16.1787 11.0802 16.5929C11.0802 17.0071 10.7444 17.3429 10.3302 17.3429H4.02944C3.61523 17.3429 3.27944 17.0071 3.27944 16.5929Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3905 6.90038C12.3905 6.48616 12.7263 6.15038 13.1405 6.15038H19.4412C19.8554 6.15038 20.1912 6.48616 20.1912 6.90038C20.1912 7.31459 19.8554 7.65038 19.4412 7.65038H13.1405C12.7263 7.65038 12.3905 7.31459 12.3905 6.90038Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6.84625C3.25 5.13132 4.64903 3.75 6.36314 3.75C8.07726 3.75 9.47629 5.13132 9.47629 6.84625C9.47629 8.56119 8.07726 9.94251 6.36314 9.94251C4.64903 9.94251 3.25 8.56119 3.25 6.84625ZM6.36314 5.25C5.46729 5.25 4.75 5.96987 4.75 6.84625C4.75 7.72263 5.46729 8.44251 6.36314 8.44251C7.259 8.44251 7.97629 7.72263 7.97629 6.84625C7.97629 5.96987 7.259 5.25 6.36314 5.25Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5237 16.5537C14.5237 14.8386 15.9229 13.4575 17.6377 13.4575C19.3521 13.4575 20.75 14.8391 20.75 16.5537C20.75 18.2683 19.3521 19.65 17.6377 19.65C15.9229 19.65 14.5237 18.2688 14.5237 16.5537ZM17.6377 14.9575C16.7408 14.9575 16.0237 15.6775 16.0237 16.5537C16.0237 17.43 16.7408 18.15 17.6377 18.15C18.5332 18.15 19.25 17.4305 19.25 16.5537C19.25 15.677 18.5332 14.9575 17.6377 14.9575Z"
        fill="#111827"
      />
    </svg>
  );
};

export const BackArrowIcon = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893Z"
        fill="#1F9AFF"
      />
    </svg>
  );
};

export const CarIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49862 13.1251H4.99862C4.65695 13.1251 4.37362 12.8417 4.37362 12.5001C4.37362 12.1584 4.65695 11.8751 4.99862 11.8751H7.49862C7.84028 11.8751 8.12362 12.1584 8.12362 12.5001C8.12362 12.8417 7.84028 13.1251 7.49862 13.1251Z"
        fill="#A0AEC0"
      />
      <path
        d="M12.4986 13.1251H14.9986C15.3403 13.1251 15.6236 12.8417 15.6236 12.5001C15.6236 12.1584 15.3403 11.8751 14.9986 11.8751H12.4986C12.1569 11.8751 11.8736 12.1584 11.8736 12.5001C11.8736 12.8417 12.1569 13.1251 12.4986 13.1251Z"
        fill="#A0AEC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74111 8.94921L3.08795 7.29174H2.49862C2.15695 7.29174 1.87362 7.00841 1.87362 6.66674C1.87362 6.32508 2.15695 6.04174 2.49862 6.04174H3.33195L3.34948 6.04199L3.66516 4.5334C3.9735 3.07507 4.5985 1.7334 7.0735 1.7334H12.9318C15.4068 1.7334 16.0318 3.0834 16.3402 4.5334L16.6552 6.04182L16.6653 6.04174H17.4986C17.8403 6.04174 18.1236 6.32508 18.1236 6.66674C18.1236 7.00841 17.8403 7.29174 17.4986 7.29174H16.9163L17.2636 8.95436C18.1998 9.48704 18.3921 10.4561 18.482 11.3834L18.9487 16.4584C19.007 17.0917 18.7987 17.7251 18.3654 18.2001C17.9237 18.6834 17.2904 18.9584 16.632 18.9584H15.0654C13.7154 18.9584 13.457 18.1834 13.2904 17.6751L13.1237 17.1751L13.1207 17.1663C12.9068 16.541 12.8785 16.4584 12.132 16.4584H7.86535C7.11535 16.4584 7.06535 16.6001 6.87369 17.1751L6.70702 17.6751L6.70331 17.686C6.52845 18.2025 6.27251 18.9584 4.93202 18.9584H3.36535C2.70702 18.9584 2.07369 18.6834 1.63202 18.2001C1.19869 17.7251 0.990355 17.0917 1.04869 16.4584L1.51535 11.3834C1.59993 10.4531 1.79514 9.48079 2.74111 8.94921ZM15.8902 8.54173H4.0985L4.89016 4.79173C5.1235 3.70007 5.3985 2.9834 7.0735 2.9834H12.9235C14.5985 2.9834 14.8735 3.70007 15.1068 4.79173L15.8902 8.54173ZM12.132 15.2084H7.86535C6.21535 15.2084 5.90702 16.1167 5.69869 16.7834L5.53202 17.2917L5.52938 17.2995C5.39034 17.7085 5.38754 17.7167 4.94035 17.7167H3.37369C3.06535 17.7167 2.76535 17.5917 2.56535 17.3667C2.36535 17.1501 2.27369 16.8751 2.29869 16.5834L2.76535 11.5084C2.86535 10.3834 2.99869 9.80008 4.68202 9.80008H15.332C17.007 9.80008 17.1404 10.3834 17.2487 11.5084L17.7154 16.5834C17.7404 16.8751 17.6487 17.1501 17.4487 17.3667C17.2404 17.5917 16.9487 17.7167 16.6404 17.7167H15.0737C14.6264 17.7167 14.6237 17.7085 14.4846 17.2912L14.482 17.2834L14.307 16.7667C14.007 15.8834 13.6487 15.2084 12.132 15.2084Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const InfoFillIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 12.75C8.5875 12.75 8.25 12.4125 8.25 12V9C8.25 8.5875 8.5875 8.25 9 8.25C9.4125 8.25 9.75 8.5875 9.75 9V12C9.75 12.4125 9.4125 12.75 9 12.75ZM9.75 6.75H8.25V5.25H9.75V6.75Z"
        fill="white"
      />
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4987 3.33301C10.9589 3.33301 11.332 3.7061 11.332 4.16634V15.833C11.332 16.2932 10.9589 16.6663 10.4987 16.6663C10.0385 16.6663 9.66536 16.2932 9.66536 15.833V4.16634C9.66536 3.7061 10.0385 3.33301 10.4987 3.33301Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83203 9.99967C3.83203 9.53944 4.20513 9.16634 4.66536 9.16634H16.332C16.7923 9.16634 17.1654 9.53944 17.1654 9.99967C17.1654 10.4599 16.7923 10.833 16.332 10.833H4.66536C4.20513 10.833 3.83203 10.4599 3.83203 9.99967Z"
        fill="white"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17.5H15H5Z"
        fill="#A0AEC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5L10 14.1667L10 2.5Z"
        fill="#A0AEC0"
      />
      <path
        d="M5 17.5H15M10 2.5L10 14.1667M10 14.1667L14.1667 10M10 14.1667L5.83333 10"
        stroke="#A0AEC0"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3346 10.0003C18.3346 14.6003 14.6013 18.3337 10.0013 18.3337C5.4013 18.3337 2.59297 13.7003 2.59297 13.7003M2.59297 13.7003H6.35964M2.59297 13.7003V17.867M1.66797 10.0003C1.66797 5.40033 5.36797 1.66699 10.0013 1.66699C15.5596 1.66699 18.3346 6.30033 18.3346 6.30033M18.3346 6.30033V2.13366M18.3346 6.30033H14.6346"
        stroke="#A0AEC0"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseCircleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
        fill="#111827"
      />
    </svg>
  );
};

export const BriefcaseIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.71305 2.21223C7.14282 1.78246 7.72571 1.54102 8.3335 1.54102H11.6668C12.2746 1.54102 12.8575 1.78246 13.2873 2.21223C13.7171 2.642 13.9585 3.22489 13.9585 3.83268V4.87435H15.8335C16.4413 4.87435 17.0242 5.11579 17.454 5.54556C17.8837 5.97533 18.1252 6.55823 18.1252 7.16602V11.5306C18.1254 11.5402 18.1254 11.5498 18.1252 11.5593V15.4993C18.1252 16.1071 17.8837 16.69 17.454 17.1198C17.0242 17.5496 16.4413 17.791 15.8335 17.791H4.16683C3.55904 17.791 2.97615 17.5496 2.54638 17.1198C2.11661 16.69 1.87517 16.1071 1.87517 15.4993V11.5593C1.87495 11.5498 1.87494 11.5402 1.87517 11.5306V7.16602C1.87517 6.55823 2.11661 5.97533 2.54638 5.54556C2.97615 5.11579 3.55904 4.87435 4.16683 4.87435H6.04183V3.83268C6.04183 3.22489 6.28328 2.642 6.71305 2.21223ZM7.29183 4.87435H12.7085V3.83268C12.7085 3.55642 12.5988 3.29146 12.4034 3.09611C12.2081 2.90076 11.9431 2.79102 11.6668 2.79102H8.3335C8.05723 2.79102 7.79228 2.90076 7.59693 3.09611C7.40158 3.29146 7.29183 3.55642 7.29183 3.83268V4.87435ZM4.16683 6.12435C3.89056 6.12435 3.62561 6.2341 3.43026 6.42945C3.23491 6.6248 3.12517 6.88975 3.12517 7.16602V11.1189C5.26154 11.9299 7.57787 12.3743 10.0002 12.3743C12.35 12.3772 14.6802 11.9513 16.8752 11.1188V7.16602C16.8752 6.88975 16.7654 6.6248 16.5701 6.42945C16.3747 6.2341 16.1098 6.12435 15.8335 6.12435H4.16683ZM10.0002 13.6243C12.3442 13.6271 14.6692 13.2292 16.8752 12.4499L10.0002 13.6243ZM16.8752 12.4499V15.4993C16.8752 15.7756 16.7654 16.0406 16.5701 16.2359C16.3747 16.4313 16.1098 16.541 15.8335 16.541H4.16683C3.89057 16.541 3.62561 16.4313 3.43026 16.2359C3.23491 16.0406 3.12517 15.7756 3.12517 15.4993V12.4501C5.27631 13.2105 7.59027 13.6243 10.0002 13.6243M9.37517 10.4993C9.37517 10.1542 9.65499 9.87435 10.0002 9.87435H10.0085C10.3537 9.87435 10.6335 10.1542 10.6335 10.4993C10.6335 10.8445 10.3537 11.1243 10.0085 11.1243H10.0002C9.65499 11.1243 9.37517 10.8445 9.37517 10.4993Z"
        fill="#111827"
      />
    </svg>
  );
};

export const TimerIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9987 4.87435C6.31888 4.87435 3.33203 7.86119 3.33203 11.541C3.33203 15.2208 6.31888 18.2077 9.9987 18.2077C13.6785 18.2077 16.6654 15.2208 16.6654 11.541C16.6654 7.86119 13.6785 4.87435 9.9987 4.87435ZM2.08203 11.541C2.08203 7.17084 5.62852 3.62435 9.9987 3.62435C14.3689 3.62435 17.9154 7.17084 17.9154 11.541C17.9154 15.9112 14.3689 19.4577 9.9987 19.4577C5.62852 19.4577 2.08203 15.9112 2.08203 11.541Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9987 6.54102C10.3439 6.54102 10.6237 6.82084 10.6237 7.16602V11.3327C10.6237 11.6779 10.3439 11.9577 9.9987 11.9577C9.65352 11.9577 9.3737 11.6779 9.3737 11.3327V7.16602C9.3737 6.82084 9.65352 6.54102 9.9987 6.54102Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8737 2.16602C6.8737 1.82084 7.15352 1.54102 7.4987 1.54102H12.4987C12.8439 1.54102 13.1237 1.82084 13.1237 2.16602C13.1237 2.51119 12.8439 2.79102 12.4987 2.79102H7.4987C7.15352 2.79102 6.8737 2.51119 6.8737 2.16602Z"
        fill="#111827"
      />
    </svg>
  );
};

export const DollarCircleIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5125 7.24089C8.99235 7.24089 8.52083 7.69246 8.52083 8.30755C8.52083 8.72645 8.61101 8.91805 8.69084 9.01988C8.77629 9.12888 8.91998 9.22572 9.20287 9.32617L12.563 10.4934C12.9372 10.6261 13.3431 10.8249 13.6429 11.2074C13.9485 11.5972 14.0792 12.0931 14.0792 12.6909C14.0792 13.9425 13.1007 15.0076 11.8375 15.0076H9.74583C8.34736 15.0076 7.27083 13.8321 7.27083 12.4409C7.27083 12.0957 7.55066 11.8159 7.89583 11.8159C8.24101 11.8159 8.52083 12.0957 8.52083 12.4409C8.52083 13.1997 9.09431 13.7576 9.74583 13.7576H11.8375C12.3577 13.7576 12.8292 13.306 12.8292 12.6909C12.8292 12.272 12.739 12.0804 12.6592 11.9786C12.5737 11.8696 12.43 11.7727 12.1471 11.6723L8.78704 10.505C8.41275 10.3723 8.00687 10.1735 7.70708 9.79105C7.40149 9.40122 7.27083 8.90532 7.27083 8.30755C7.27083 7.05598 8.24932 5.99089 9.5125 5.99089H11.6042C13.0026 5.99089 14.0792 7.16637 14.0792 8.55755C14.0792 8.90273 13.7993 9.18255 13.4542 9.18255C13.109 9.18255 12.8292 8.90273 12.8292 8.55755C12.8292 7.79873 12.2557 7.24089 11.6042 7.24089H9.5125Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6693 4.87435C11.0144 4.87435 11.2943 5.15417 11.2943 5.49935V15.4993C11.2943 15.8445 11.0144 16.1243 10.6693 16.1243C10.3241 16.1243 10.0443 15.8445 10.0443 15.4993V5.49935C10.0443 5.15417 10.3241 4.87435 10.6693 4.87435Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6693 2.79102C6.41208 2.79102 2.96094 6.24215 2.96094 10.4993C2.96094 14.7565 6.41208 18.2077 10.6693 18.2077C14.9265 18.2077 18.3776 14.7565 18.3776 10.4993C18.3776 6.24215 14.9265 2.79102 10.6693 2.79102ZM1.71094 10.4993C1.71094 5.5518 5.72172 1.54102 10.6693 1.54102C15.6168 1.54102 19.6276 5.5518 19.6276 10.4993C19.6276 15.4469 15.6168 19.4577 10.6693 19.4577C5.72172 19.4577 1.71094 15.4469 1.71094 10.4993Z"
        fill="#111827"
      />
    </svg>
  );
};

export const ChartIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47619 8.37578C6.82137 8.37578 7.10119 8.6556 7.10119 9.00078V14.7176C7.10119 15.0627 6.82137 15.3426 6.47619 15.3426C6.13101 15.3426 5.85119 15.0627 5.85119 14.7176V9.00078C5.85119 8.6556 6.13101 8.37578 6.47619 8.37578Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3651 5.64029C10.7103 5.64029 10.9901 5.92011 10.9901 6.26529V14.7176C10.9901 15.0627 10.7103 15.3426 10.3651 15.3426C10.0199 15.3426 9.74008 15.0627 9.74008 14.7176V6.26529C9.74008 5.92011 10.0199 5.64029 10.3651 5.64029Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1905 11.3967C14.5357 11.3967 14.8155 11.6765 14.8155 12.0217V14.7176C14.8155 15.0627 14.5357 15.3426 14.1905 15.3426C13.8453 15.3426 13.5655 15.0627 13.5655 14.7176V12.0217C13.5655 11.6765 13.8453 11.3967 14.1905 11.3967Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73629 3.04415C3.63349 2.08171 4.9127 1.54102 6.42857 1.54102H14.2381C15.7575 1.54102 17.0371 2.08146 17.9336 3.04445C18.8255 4.00239 19.2917 5.32755 19.2917 6.82031V14.1784C19.2917 15.6712 18.8255 16.9963 17.9336 17.9542C17.0371 18.9172 15.7575 19.4577 14.2381 19.4577H6.42857C4.90913 19.4577 3.62958 18.9172 2.73304 17.9542C1.84119 16.9963 1.375 15.6712 1.375 14.1784V6.82031C1.375 5.32678 1.84363 4.00173 2.73629 3.04415ZM3.65062 3.8965C3.00955 4.58419 2.625 5.58628 2.625 6.82031V14.1784C2.625 15.4132 3.00802 16.4152 3.64792 17.1025C4.28311 17.7848 5.21785 18.2077 6.42857 18.2077H14.2381C15.4488 18.2077 16.3836 17.7848 17.0188 17.1025C17.6586 16.4152 18.0417 15.4132 18.0417 14.1784V6.82031C18.0417 5.58552 17.6586 4.58353 17.0188 3.89621C16.3836 3.21394 15.4488 2.79102 14.2381 2.79102H6.42857C5.22222 2.79102 4.28715 3.21369 3.65062 3.8965Z"
        fill="#111827"
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33073 5.29167C4.75543 5.29167 4.28906 5.75804 4.28906 6.33333V16.3333C4.28906 16.9086 4.75543 17.375 5.33073 17.375H15.3307C15.906 17.375 16.3724 16.9086 16.3724 16.3333V6.33333C16.3724 5.75804 15.906 5.29167 15.3307 5.29167H5.33073ZM3.03906 6.33333C3.03906 5.06768 4.06508 4.04167 5.33073 4.04167H15.3307C16.5964 4.04167 17.6224 5.06768 17.6224 6.33333V16.3333C17.6224 17.599 16.5964 18.625 15.3307 18.625H5.33073C4.06508 18.625 3.03906 17.599 3.03906 16.3333V6.33333Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6641 2.375C14.0092 2.375 14.2891 2.65482 14.2891 3V6.33333C14.2891 6.67851 14.0092 6.95833 13.6641 6.95833C13.3189 6.95833 13.0391 6.67851 13.0391 6.33333V3C13.0391 2.65482 13.3189 2.375 13.6641 2.375Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9974 2.375C7.34257 2.375 7.6224 2.65482 7.6224 3V6.33333C7.6224 6.67851 7.34257 6.95833 6.9974 6.95833C6.65222 6.95833 6.3724 6.67851 6.3724 6.33333V3C6.3724 2.65482 6.65222 2.375 6.9974 2.375Z"
        fill="#111827"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03906 9.66667C3.03906 9.32149 3.31888 9.04167 3.66406 9.04167H16.9974C17.3426 9.04167 17.6224 9.32149 17.6224 9.66667C17.6224 10.0118 17.3426 10.2917 16.9974 10.2917H3.66406C3.31888 10.2917 3.03906 10.0118 3.03906 9.66667Z"
        fill="#111827"
      />
    </svg>
  );
};
