import AllurFinanceLogo from './media/logo.png';
import expandLogo from './media/logo_expand.png';
import shieldIcon from './media/icons/shield-tick.png';
import carIcon from './media/icons/car.png';
import clipBoardIcon from './media/icons/clipboard-tick.png';
import filetextIcon from './media/icons/file-text.png';
import fileText from './media/icons/file-text.png';
import percentageIcon from './media/icons/percentage-square.png';
import posIcon from './media/icons/pos.png';
import questionIcon from './media/icons/question-circle-outlined.png';
import settingIcon from './media/icons/settings.png';
import usersIcon from './media/icons/users.png';
import layoutIcon from './media/icons/layout-grid.png';
import bellIcon from './media/icons/bell.png';
import calcIcon from './media/icons/calc.png';
import filterIcon from './media/icons/Filter.png';
import searchIcon from './media/icons/Search.png';
import closeXicon from './media/icons/x.png';
import freedomlogo from './media/icons/freedom_Logo.png';
import bckLogo from './media/icons/bck_Logo.png';
import altynLogo from './media/icons/altyn_logo.png';
import berekeLogo from './media/icons/bereke_logo.png';
import eurasLogo from './media/icons/euras_logo.png';
import halykLogo from './media/icons/halyk_logo.png';
import kmfLogo from './media/icons/kmf_logo.png';
import logoBg from './media/login_bg.jpg';
import logoAllurRed from './media/svg/logo_red.svg';
import trashIcon from './media/icons/trash_icon.png';
import photoIcon from './media/icons/photo_icon.png';
import cameraIcon from './media/icons/camera_icon.png';
import info from './media/icons/info.png';
import infoGrey from './media/icons/info-circle.png';
import printer from './media/icons/printer.png';
import chevronDown from './media/icons/chevron-down.png';
import redDownload from './media/icons/red_download.png';
import penIcon from './media/icons/edit_pen_icon.png';
import pndIcon from './media/icons/pnd_icon.png';
// import baselicon from './media/icons/basel.png'
import darkInfoCircle from './media/icons/dark-info-circle.png';
import darkPlus from './media/icons/dark-plus.png';
import darkMinus from './media/icons/dark-minus.png';
import warning from './media/icons/warning-fill.png';
import calculatorPlaceholder from './media/icons/calculatorPlaceholder.png';
import shinHanLogo from './media/icons/shinhan-bank.png';
import forteLogo from './media/icons/forte_logo.png';
import circleQuestion from './media/icons/question-circle-outlined-lighter.png';
import phone from './media/icons/phone.png';

export const pathLogo = AllurFinanceLogo;

export const pathCalculatorPlaceholder = calculatorPlaceholder;
export const pathExpandLogo = expandLogo;
export const pathSettingIcon = settingIcon;
export const pathQuestionicon = questionIcon;
export const pathBellIcon = bellIcon;
export const pathCalcIcon = calcIcon;
export const pathFilterIcon = filterIcon;
export const pathSearchIcon = searchIcon;
export const pathCloseXIcon = closeXicon;
export const pathFreedomlogo = freedomlogo;
export const pathBckLogo = bckLogo;
export const pathBerekeLogo = berekeLogo;
export const pathAltynLogo = altynLogo;
export const pathEurasLogo = eurasLogo;
export const pathForteLogo = forteLogo;
export const pathHalykLogo = halykLogo;
export const pathKmfLogo = kmfLogo;
export const shinhanPathLogo = shinHanLogo;
export const pathLogoBg = logoBg;
export const pathLogoAllurRed = logoAllurRed;
export const pathTrashIcon = trashIcon;
export const pathPhotoIcon = photoIcon;
export const pathCameraIcon = cameraIcon;
export const infoIcon = info;
export const greyInfoIcon = infoGrey;
export const pathFileText = fileText;
export const pathRedDownload = redDownload;
export const pathPenIcon = penIcon;
export const chevronDownIcon = chevronDown;
export const printerLogo = printer;
export const darkInfoCircleLogo = darkInfoCircle;
export const darkPlusIcon = darkPlus;
export const darkMinusIcon = darkMinus;
export const warningIcon = warning;
export const circleQuestionIcon = circleQuestion;
export const phoneIcon = phone;

// export const baselicon = baselicon;

export const sideBarItems: {
  label: string;
  key: string;
  iconPath: string;
  rolesPermitted: string[];
}[] = [
  { label: 'Главная', key: 'main', iconPath: layoutIcon, rolesPermitted: [] },
  {
    label: 'Автокредитование',
    key: 'auto-credit',
    iconPath: carIcon,
    rolesPermitted: []
  },
  {
    label: 'Страхование',
    key: 'insurance',
    iconPath: shieldIcon,
    rolesPermitted: []
  },
  {
    label: 'POS-кредитование',
    key: 'pos-cred',
    iconPath: posIcon,
    rolesPermitted: []
  },
  {
    label: 'Урегулирование',
    key: 'settlement',
    iconPath: clipBoardIcon,
    rolesPermitted: []
  },
  {
    label: 'Лизинг',
    key: 'leasing',
    iconPath: percentageIcon,
    rolesPermitted: []
  },
  { label: 'Клиенты', key: 'clients', iconPath: usersIcon, rolesPermitted: [] },
  {
    label: 'Доп. продукт (ПНД)',
    key: 'additional-product-pnd',
    iconPath: pndIcon,
    rolesPermitted: []
  },
  {
    label: 'Отчеты',
    key: 'reports',
    iconPath: filetextIcon,
    rolesPermitted: []
  }
];

export enum DictTypes {
  PropertyTypes = 'Property_Types', //Вид собственности
  ProductsTypes = 'Product_Types', //Типы продуктов
  MainActivities = 'Main_Activities', //Основной вид деятельности
  DocumentIssuringAuthorities = 'Document_Issuing_Authorities', // Кем выдан
  DocumentTypes = 'Document_Types', // Тип документа
  ResidentStatuses = 'Resident_Statuses', // Статус резидента
  MaritalStatuses = 'Marital_Statuses', // Семейное положение
  SocialStatuses = 'Social_Statuses', // Социальный статус
  OrganizationTypes = 'Organization_Types', // Вид организации
  Insurances = 'Insurances', //Страховые компании
  EducationTypes = 'Education_Types', // Образование
  // PropertyTypes = 'Property_Types', // Тип дома
  CompanyActivityTypes = 'Company_Activity_Types', // Сфера деятельности организации
  RepaymentTypes = 'Repayment_Types', // Схема оплаты
  Statuses = 'Statuses', // Статусы
  VehicleRegistrationCompanies = 'Vehicle_Registration_Companies',
  Genders = 'Genders', // Пол
  LoanTerms = 'Loan_Terms', // Срок займа
  EngineTypes = 'Engine_Types', // Тип двигателя
  Dealers = 'Dealers', // Диллеры
  AutoTypes = 'Auto_Types', // Тип авто
  PaymentDates = 'Payment_Dates', // Дата платежа
  Colors = 'Colors', // Цвет авто
  AddContactsIsRelated = 'Add_Contacts_Is_Related', // Кем приходится
  Brands = 'Brands', // Марка
  Models = 'Models', // Модель
  Years = 'Years', // Год выпуска
  Countries = 'Countries', //Страны
  Products = 'Products', //Продукты (StockBoundaries)
  Banks = 'Banks', // Интегрированные Банки
  InsurancePayment = 'Insurance_Payment_Types', // способ оплаты
  InsuranсeInstallmentTypes = 'Insurance_Installment_Types', // тип оплаты
  Insurance_Installment_Periods = 'Insurance_Installment_Periods', // период рассрочки
  //InsurancePayment = 'Insurance_Payment_Type', // выбор оплты страхования
  Cities = 'Cities', // Список городов
  ProductTypes = 'Product_Types' // Тип продукта
}

export const statusColors = {
  draft: {
    bgColor: 'rgb(17 24 39)',
    textColor: 'white'
  },
  approved: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  declined: {
    bgColor: '#E03137',
    textColor: 'white'
  },
  waiting_bank_response: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  error: {
    bgColor: '#E03137',
    textColor: 'white'
  },
  financed: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  cancelled: {
    bgColor: '#E03137',
    textColor: 'white'
  },
  waiting_of_financing: {
    bgColor: '#1f9aff',
    textColor: 'white'
  },
  waiting_for_upload_files: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  accepted_alternative: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  on_rework: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  on_verification: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  financed_pledge_missing: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  },
  sms_verificating: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  pre_approved: {
    bgColor: '#0CAF60',
    textColor: 'white'
  },
  mobile_client: {
    bgColor: '#FFD023',
    textColor: 'black'
  },
  financed_waiting_for_modification_before_pledge: {
    bgColor: '#1F9AFF',
    textColor: 'white'
  }
};

export const statusTexts = {
  draft: 'Черновик',
  approved: 'Одобрено',
  declined: 'Ошибка',
  waiting_bank_response: 'На рассмотрении',
  error: 'Ошибка',
  financed: 'Профинансировано',
  cancelled: 'Ошибка',
  waiting_of_financing: 'Финансирование',
  waiting_for_upload_files: 'Ожидание загрузки файлов',
  accepted_alternative: 'Одобрена альтернатива',
  on_rework: 'На рассмотрении',
  on_verification: 'На рассмотрении',
  financed_pledge_missing: 'Финансирование',
  sms_verificating: 'На рассмотрении',
  pre_approved: 'Предварительное одобрение',
  mobile_client: 'На рассмотрении',
  financed_waiting_for_modification_before_pledge: 'Финансирование'
};

export enum BanksColors {
  FreedomBank = '#85B447',
  EurasBank = '#EA346F',
  HalykBank = '#E6BB20',
  BccBank = '#DAAB6B',
  ForteBank = '#4BA9B8',
  KMFBank = '#D4310F',
  BerekeBank = '#2DD4BF'
}

export enum SystemActions {
  CREATE_REQUEST = 1,
  VIEW_ALL_REQUESTS,
  ROLE_MANAGMENT
}

export const REGISTRATION_DUTY_COST = 46500;

export enum CALCULATION_TYPES {
  STANDART_ANUETET = 'Аннуитет',
  STANDART_DIFERENCE = 'Дифференцированный',
  SUBSIDES = 'Субсидии'
}

export enum ADDITIONAL_FILES_TYPES {
  SPOUSE_AGREEMENT = 'spouseConsent'
}
