import '@/styles/FinancingStep.scss';
import React, { useEffect, useState } from 'react';
import { HalykBank } from './Banks/HalykBank';
import { ForteBank } from './Banks/ForteBank';
import { ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { ModalTS } from './ModalTS/ModalTS';
import { EurasBank } from './Banks/EurasBank';
import { Button, notification } from 'antd';
import { BerekeBank } from './Banks/BerekeBank';
import { BccBank } from './Banks/BccBank';
import { FreedomBank } from './Banks/FreedomBank';
import { useNavigate } from 'react-router-dom';
import { dispatch, useAppSelector } from '@/store';
import {
  TDocumentsOnModeration,
  TUploadAdditionalFilesDto
} from '@/types/uploadFiles';
import { TModalTSValues } from '@/types/financing';
import {
  changeOfferStatus,
  getEblankStatus,
  getForteStatus,
  resetOfferUuid
} from '@/store/offerStep.slice';
import { changeRequestStatus } from '@/store/request.slice';
import {
  getAttachedFilesByReqId,
  onSetFinancingStoreToInitialState,
  onSetSelectedBank,
  setIsFilesUpdated
} from '@/store/financingStep.slice';
import { findDictByTitle, getFormattedBankName, getImgPath } from '@/services';
import { DictTypes, pathPenIcon } from '@/constants';
import { apiConnector } from '@/integrations/api.connector';
import { setPageTitle } from '@/store/newOrder.slice';
import { modalTsDtoMapper } from '@/services/mappers/modalTsDtoMapper';
import { CustomModal } from '@/components/Shared/Modal/CustomModal';
import { FileDownload } from '@/components/Shared/Input/FileDownload';
import ShinhanBank from '@/components/App/NewOrder/FinancingStep/Banks/ShinhanBank';
import { downloadFilesApi } from '@/integrations/index.api';
import { AltynBank } from '@/components/App/NewOrder/FinancingStep/Banks/AltynBank';
import useFilters from '@/hooks/useFilters';

export const FinancingStep = () => {
  const { tsModalValues, requestId, clientInfo, orderApp } = useAppSelector(
    (state) => state.newOrder
  );
  const { selectedBank, attachedFiles, isFilesUpdated } = useAppSelector(
    (state) => state.financingStep
  );

  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { banksOffers, offerUuid } = useAppSelector((state) => state.offerStep);
  const { dicts } = useAppSelector((state) => state.dict);

  const [show, setShow] = useState<boolean>(false);
  const [values, setValues] = useState<TModalTSValues>(tsModalValues);
  const [forteStatusLoading, setForteStatusLoading] = useState<boolean>(false);
  const [infoTS, setInfoTS] = useState<boolean>(false);
  const [isSendAvailable, setIsSendAvailable] = useState<boolean>(false);
  const [isPledgeRegister, setPledgeRegister] = useState<boolean>(false);
  const [isEblankFilesUpdating, setIsEblankFilesUpdating] =
    useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [documentsOnModeration, setDocumentsOnModeration] = useState<
    TDocumentsOnModeration[]
  >([]);
  const [forteBankStatus, setForteBankStatus] = useState<string>('');
  const [pollingActive, setPollingActive] = useState<boolean>(false);

  const navigate = useNavigate();
  const { handleFiltersChange } = useFilters<{ tab: number }>([
    { key: 'tab', defaultValue: 3 }
  ]);

  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!selectedBank) handleFiltersChange({ tab: 2 });
  }, [selectedBank]);

  const handleOpenModal = (bankDescription: string) => {
    if (!bankDescription) {
      setDescription('Описание недоступно.');
      setShowDescriptionModal(true);
      return;
    }
    const isValidJSON = (str: string) => {
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    };
    if (isValidJSON(bankDescription)) {
      try {
        const parsedDescription = JSON.parse(bankDescription);
        if (parsedDescription.status_code) {
          setDescription(parsedDescription.status_code);
        } else if (parsedDescription.stateTitle) {
          setDescription(parsedDescription.stateTitle);
        } else {
          setDescription(JSON.stringify(parsedDescription, null, 2));
        }
      } catch (error) {
        setDescription('Описание недоступно.');
      }
    } else {
      setDescription(bankDescription);
    }

    setShowDescriptionModal(true);
  };

  const handleCloseModal = () => {
    setShowDescriptionModal(false);
  };

  const onCancelHandler = () => {
    setShowCancelModal(true);
  };

  const changeSendAvailableStatus = (isValid: boolean) => {
    setIsSendAvailable(isValid);
  };
  const modalCancelHandler = async () => {
    if (selectedBank) {
      const data: {
        res_id: string;
        is_offered: boolean;
        offered_uuid: string | null;
        status:
          | 'approved'
          | 'declined'
          | 'waiting_bank_response'
          | 'error'
          | 'financed'
          | 'cancelled'
          | 'waiting_of_financing'
          | 'accepted_alternative';
      } = {
        res_id: selectedBank.id,
        is_offered: false,
        offered_uuid: offerUuid,
        status: 'cancelled'
      };
      await dispatch(changeOfferStatus(data)).then(async () => {
        await apiConnector.cancelBankResponse({
          bankName: selectedBank?.name || '',
          extId: selectedBank?.extId || '',
          requestId: requestId,
          responseId: selectedBank?.id || '',
          userLogin: userInfo?.login || ''
        });

        requestId &&
          (await dispatch(
            changeRequestStatus({
              reqId: requestId,
              newStatus: 'cancelled'
            })
          ));
        dispatch(onSetFinancingStoreToInitialState());
        setShowCancelModal(false);
        navigate('/');
      });
    }
    dispatch(resetOfferUuid());
  };

  const handleDownloadHalykGuarantee = async () => {};

  const handleUpdateForteStatus = async () => {
    try {
      setForteStatusLoading(true);
      const statusResponse = await dispatch(
        getForteStatus(selectedBank?.extId || '')
      );
      const status = statusResponse.payload;
      setForteBankStatus(status);

      if (status === 'waiting_of_bank_response') {
        setPollingActive(true);
      }

      setTimeout(async () => {
        await dispatch(getAttachedFilesByReqId(requestId));
        notification.success({
          message: 'Данные обновлены',
          description: 'Данные были обновлены',
          duration: 5
        });
        setForteStatusLoading(false);
      }, 10000);
    } catch (e: any) {
      notification.error({
        message: 'Ошибка',
        description: 'Ошибка обновления файлов',
        duration: 5
      });
      setForteStatusLoading(false);
      throw new Error(e);
    }
  };
  useEffect(() => {
    if (pollingActive) {
      const pollingInterval = 10000; // 10 секунд
      const pollingDuration = 120000; // 2 минуты
      let elapsed = 0;

      const interval = setInterval(async () => {
        elapsed += pollingInterval;

        if (elapsed >= pollingDuration) {
          clearInterval(interval);
          setPollingActive(false);
          console.log('Переопрос статуса завершен');
          notification.info({
            message: 'Переопрос статуса завершен',
            description: 'Переопрос статуса от банка был завершен.',
            duration: 5
          });
          return;
        }
        const statusResponse = await dispatch(
          getForteStatus(selectedBank?.extId || '')
        );
        const status = statusResponse.payload;
        setForteBankStatus(status);

        if (status !== 'waiting_of_bank_response') {
          clearInterval(interval);
          setPollingActive(false);
          console.log('Статус обновлен', status);
          notification.success({
            message: 'Статус обновлен',
            description: 'Статус был успешно обновлен.',
            duration: 5
          });
        }
      }, pollingInterval);

      return () => clearInterval(interval);
    }
  }, [pollingActive]);

  useEffect(() => {
    // console.log('Изменился статус ForteBank', forteBankStatus);
    if (forteBankStatus === 'waiting_of_bank_response') {
      setPollingActive(true);
    }
  }, [forteBankStatus]);

  const handlePnutEblankFilesManualy = async () => {
    try {
      setIsEblankFilesUpdating(true);
      await downloadFilesApi.pnutUebankManually(selectedBank?.id || '');
      setTimeout(async () => {
        await dispatch(getAttachedFilesByReqId(requestId));
        setIsEblankFilesUpdating(false);
        notification.success({
          message: 'Файлы обновлены',
          description: 'Файлы были обновлены',
          duration: 5
        });
        setIsEblankFilesUpdating(false);
      }, 25000);
    } catch (e: any) {
      setIsEblankFilesUpdating(false);
      notification.error({
        message: 'Ошибка',
        description: 'Ошибка обновления файлов',
        duration: 5
      });
      throw new Error(e);
    }
  };

  const parseApprovedBody = () => {
    try {
      const parsedLogBody = JSON.parse(
        selectedBank?.responseApprovedBody || '{}'
      );
      if (
        parsedLogBody?.modification &&
        selectedBank?.statusType === 'on_rework'
      ) {
        setDocumentsOnModeration(parsedLogBody.modification);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(setIsFilesUpdated(true));
    dispatch(setPageTitle('Финансирование'));
  }, []);

  useEffect(() => {
    if (isFilesUpdated) {
      dispatch(getAttachedFilesByReqId(requestId));
      dispatch(setIsFilesUpdated(false));
    }
  }, [isFilesUpdated]);

  useEffect(() => {
    parseApprovedBody();
  }, [selectedBank]);

  useEffect(() => {
    const isEmpty = Object.values(values).every((value) => value !== '');
    // isEmpty && setInfoTS(true);
    if (isEmpty) {
      if (selectedBank?.bank !== 'altyn') {
        setInfoTS(true);
      } else if (selectedBank?.bank === 'altyn') {
        if (
          selectedBank.statusType ===
          'financed_waiting_for_modification_before_pledge'
        ) {
          setInfoTS(true);
        }
      }
    }
  }, []);

  const okHandler = async () => {
    try {
      const isNotEmpty = Object.values(values).every((value) => value !== '');
      if (isNotEmpty) {
        setInfoTS(true);
        setShow(false);
        const mappedTSInfo = modalTsDtoMapper(values);
        await apiConnector.updateVehicleInfo({
          ...mappedTSInfo,
          bank: selectedBank?.bank || '',
          response_id: selectedBank?.id || '',
          ext_uuid: selectedBank?.extId || ''
        });
        selectedBank?.bank === 'eu_bank' &&
          (await handlePnutEblankFilesManualy());
        selectedBank?.bank === 'forte' && (await handleUpdateForteStatus());
        notification.info({
          message: 'Данные ТС были добавлены.',
          duration: 5
        });
      } else {
        notification.warning({
          message: 'Заполните все поля.',
          duration: 5
        });
      }
    } catch (e: any) {
      notification.error({
        message: 'Ошибка загрузки данных ТС.',
        duration: 5
      });
      throw new Error(e);
    }
  };

  const handleChangeEblankStatus = async () => {
    try {
      const data = {
        requestId: requestId,
        responseExtUuid: selectedBank?.extId || ''
      };
      await dispatch(getEblankStatus(data));
      notification.success({
        message: '',
        description: 'Статусы банка обновлены',
        duration: 5
      });
    } catch (e: any) {
      notification.error({
        message: '',
        description: 'Ошибка обновления статуса',
        duration: 5
      });
      throw new Error(e);
    }
  };

  // useEffect(() => {
  //   //@ts-ignore
  //   const test = JSON.parse(JSON.parse(selectedBank.responsePrimaryBody));
  //   const responsePrimaryBodyPars = JSON.parse(test.response);
  //   console.log(responsePrimaryBodyPars);
  // }, []);

  const sendDocumentsHandler = async (uploadedFiles: {
    [key: string]: any;
  }) => {
    setRequestLoading(true);

    try {
      const dataArray: TUploadAdditionalFilesDto[] = Object.keys(
        uploadedFiles
      ).map((key) => {
        let bccParseData = null;
        if (selectedBank?.bank === 'bcc' && selectedBank?.responsePrimaryBody) {
          const firstParse = JSON.parse(
            JSON.parse(selectedBank.responsePrimaryBody)
          );
          bccParseData = JSON.parse(firstParse.response);
        }
        const item: TUploadAdditionalFilesDto = {
          bank_id:
            findDictByTitle(DictTypes.Banks, dicts)
              .content.find((bank) => bank.name === selectedBank?.bank)
              ?.code?.toString() || '',
          base64: uploadedFiles[key]?.base64 || '',
          iin: clientInfo?.iin || '',
          name: uploadedFiles[key]?.name || '',
          type: key,
          request_uuid: requestId,
          response_uuid: selectedBank?.id || '',
          bank_payload:
            selectedBank?.bank === 'eu_bank'
              ? { with_pledge: isPledgeRegister }
              : selectedBank?.bank === 'halyk'
              ? {
                  married: Number(orderApp?.personalInfo?.familyStatus)
                }
              : selectedBank?.bank === 'forte'
              ? {
                  spouse_agreement_id:
                    JSON.parse(selectedBank?.responseApprovedBody || '{}')
                      ?.spouse_agreement_id || '',

                  commitment_letter_id:
                    JSON.parse(selectedBank?.responseApprovedBody || '{}')
                      ?.commitment_letter_id || '',
                  vin_code: tsModalValues?.vin || values?.vin || '',
                  marital_status_id:
                    JSON.parse(selectedBank?.responseApprovedBody || '{}')
                      ?.marital_status_id || '',
                  no_spouse_agreement_id:
                    JSON.parse(selectedBank?.responseApprovedBody || '{}')
                      ?.no_spouse_agreement_id || ''
                }
              : selectedBank?.bank === 'bcc'
              ? {
                  viewIdBroker: bccParseData
                    ? bccParseData?.viewIdBroker || ''
                    : '',
                  processId: bccParseData ? bccParseData?.processId || '' : ''
                }
              : null
        };
        return item;
      });
      await apiConnector.uploadAdditionalFiles(dataArray);
      notification.success({
        message: 'Файлы отправлены',
        description: 'Прикрепленные файлы были успешно отправлены',
        duration: 5
      });
    } catch (e: unknown) {
      notification.error({
        message: 'Ошибка',
        description: 'Ошибка при отправке файлов',
        duration: 5
      });
      console.error(e);
    }

    setRequestLoading(false);
  };

  useEffect(() => {
    setPledgeRegister(
      selectedBank?.statusType === 'financed_pledge_missing' ||
        selectedBank?.statusType ===
          'financed_waiting_for_modification_before_pledge'
    );
  }, [selectedBank?.statusType]);

  useEffect(() => {
    if (selectedBank?.bank === 'eu_bank') {
      const eblank = banksOffers.find((offer) => offer.bank === 'eu_bank');
      eblank && dispatch(onSetSelectedBank(eblank));
    }
  }, [banksOffers]);

  return (
    <div className="financingStep">
      <CustomModal
        editClass="cancel"
        title="Вы точно хотите отменить предложение?"
        isModalOpen={showCancelModal}
        handleOk={modalCancelHandler}
        handleCancel={() => setShowCancelModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowCancelModal(false)}>
            Нет
          </Button>,
          <Button
            className="cancel_btn"
            key="submit"
            type="primary"
            onClick={modalCancelHandler}
          >
            Да, отменить
          </Button>
        ]}
      />

      <ModalTS
        show={show}
        setShow={setShow}
        okHandler={okHandler}
        values={values}
        setValues={setValues}
        isFinanced={selectedBank?.statusType === 'financed'}
      />
      <div className="bank_content">
        <div className="title">
          <h2>Документы</h2>
          <div className="title_pagination">
            <Button
              // loading={isEblankFilesUpdating}
              icon={<UploadOutlined />}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                padding: '0',
                border: 'none',
                borderRadius: '0'
              }}
              // onClick={handlePnutEblankFilesManualy}
            >
              <span
                style={{
                  marginRight: '10px',
                  marginBottom: '5px',
                  borderBottom: '1px dotted black'
                }}
              >
                Скачать все документы
              </span>
            </Button>
            <Button
              loading={isEblankFilesUpdating}
              icon={<ReloadOutlined />}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                padding: '0',
                border: 'none',
                borderRadius: '0'
              }}
              onClick={handlePnutEblankFilesManualy}
            >
              <span
                style={{
                  marginRight: '10px',
                  marginBottom: '5px',
                  borderBottom: '1px dotted black'
                }}
              >
                Получить документы
              </span>
            </Button>
          </div>
        </div>
        <div className="upload_files">
          {selectedBank?.bank === 'altyn' && (
            <AltynBank
              status={selectedBank.statusType}
              attachedFiles={attachedFiles}
              handleSendDocs={sendDocumentsHandler}
              requestLoading={requestLoading}
              showSendButton={selectedBank?.statusType !== 'financed'}
            />
          )}
          {selectedBank?.bank === 'halyk' && (
            // <HalykBank changeHalykValidStatus={changeSendAvailableStatus} />
            <HalykBank
              isFileDownloading={isFileLoading}
              requestLoading={requestLoading}
              handleDownloadHalykGuarantee={handleDownloadHalykGuarantee}
              isOfferFinaced={selectedBank?.statusType === 'financed'}
              // showSendButton={selectedBank?.statusType !== 'financed'}
              handleSendDocs={sendDocumentsHandler}
              attachedFiles={attachedFiles}
            />
          )}
          {selectedBank?.bank === 'forte' && (
            <ForteBank
              infoTS={infoTS}
              isForteStatusLoading={forteStatusLoading}
              updateForteStatus={handleUpdateForteStatus}
              attachedFiles={attachedFiles}
              handleSendDocs={sendDocumentsHandler}
              requestLoading={requestLoading}
              isOfferFinanced={selectedBank?.statusType === 'financed'}
              pollingActive={pollingActive}
            />
          )}
          {selectedBank?.bank === 'eu_bank' && (
            <EurasBank
              infoTS={infoTS}
              documentsOnModeration={documentsOnModeration}
              key={'eu_bank'}
              attachedFiles={attachedFiles}
              requestLoading={requestLoading}
              updateEblankStatus={handleChangeEblankStatus}
              isPledgeRegister={isPledgeRegister}
              setPledgeRegister={setPledgeRegister}
              isOfferFinanced={selectedBank?.statusType === 'financed'}
              handleSendDocs={sendDocumentsHandler}
              isPledgeMissing={
                selectedBank?.statusType ===
                  'financed_waiting_for_modification_before_pledge' ||
                selectedBank?.statusType === 'financed_pledge_missing'
              }
            />
          )}
          {selectedBank?.bank === 'bereke' && (
            <BerekeBank
              infoTS={infoTS}
              attachedFiles={attachedFiles}
              isOfferFinanced={selectedBank?.statusType === 'financed'}
              handleSendDocs={sendDocumentsHandler}
            />
          )}
          {selectedBank?.bank === 'bcc' && (
            <BccBank
              showSendButton={selectedBank?.statusType !== 'financed'}
              handleSendDocs={sendDocumentsHandler}
              attachedFiles={attachedFiles}
              requestLoading={requestLoading}
            />
          )}
          {selectedBank?.bank === 'freedom' && (
            <FreedomBank changeFreedomValidStatus={changeSendAvailableStatus} />
          )}
          {selectedBank?.bank === 'shinhan' && (
            <ShinhanBank
              attachedFiles={attachedFiles}
              showSendButton={false}
              handleSendDocs={sendDocumentsHandler}
              requestLoading={requestLoading}
            />
          )}
        </div>
      </div>
      <div className="status_content">
        <div className="info">
          <div className="info_content">
            <img src={getImgPath(selectedBank?.bank || '')} alt="bank_logo" />
            <div className="text">
              <p>{selectedBank ? 'Выбран банк' : 'Банка не выбрано'}</p>
              <h3>{getFormattedBankName(selectedBank?.name || '')}</h3>
            </div>
          </div>
          {selectedBank &&
          selectedBank?.statusType !== 'financed' &&
          selectedBank?.statusType !== 'cancelled' ? (
            <button
              // onClick={() => dispatch(setShowAlertModal(true))}
              onClick={onCancelHandler}
            >
              Отменить предложение
            </button>
          ) : null}
          {selectedBank?.statusType === 'mobile_client' && (
            <p
              style={{
                marginTop: '10px',
                background: '#FFD023',
                borderRadius: '10px',
                color: 'white', //'#A0AEC0',
                padding: '5px',
                fontSize: '12px'
              }}
            >
              Работа в мобильном приложении
            </p>
          )}
          {selectedBank?.statusType === 'financed' ? (
            <p className="request_financed">FINANCED</p>
          ) : null}
          {selectedBank?.statusType === 'cancelled' ? (
            <p className="request_canceled">CANCELED</p>
          ) : null}
          <div>
            {selectedBank?.bankDescription && (
              <Button
                onClick={() =>
                  handleOpenModal(selectedBank?.bankDescription || '')
                }
                style={{
                  borderRadius: '10px',
                  borderColor: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                Описание
              </Button>
            )}

            <CustomModal
              title="Текущий статус"
              isModalOpen={showDescriptionModal}
              handleOk={handleCloseModal}
              handleCancel={handleCloseModal}
              editClass={''}
              footer={[]}
            >
              <p>{description}</p>
            </CustomModal>
          </div>

          {selectedBank?.statusType === 'financed' &&
            ['bcc'].includes(selectedBank?.bank) && (
              <div className="Payment_link">
                <FileDownload
                  prevStateTitle={'Платежное поручение'}
                  fileLink={
                    attachedFiles.find(
                      (file) => file.type === 'paymentOrderBcc'
                    )?.uuid || ''
                  }
                />
              </div>
            )}
          {selectedBank?.statusType === 'financed' &&
            ['halyk'].includes(selectedBank?.bank) && (
              <div className="Payment_link">
                <FileDownload
                  prevStateTitle={'Фискальный чек'}
                  fileLink={
                    attachedFiles.find(
                      (file) => file.type === 'PaymentOrderHalyk'
                    )?.uuid || ''
                  }
                />
              </div>
            )}
          {selectedBank?.statusType === 'financed' &&
            ['forte'].includes(selectedBank?.bank) && (
              <div className="Payment_link">
                <FileDownload
                  prevStateTitle={'Платежное поручение'}
                  fileLink={
                    attachedFiles.find(
                      (file) => file.type === 'PaymentOrderForte'
                    )?.uuid || ''
                  }
                />
              </div>
            )}
          {selectedBank?.statusType === 'financed' &&
            ['altyn'].includes(selectedBank?.bank) && (
              <div className="Payment_link">
                <FileDownload
                  prevStateTitle={'Платежное поручение'}
                  fileLink={
                    attachedFiles.find(
                      (file) => file.type === 'paymentOrderAltyn'
                    )?.uuid || ''
                  }
                />
              </div>
            )}
        </div>
        {(['forte', 'bereke', 'eu_bank'].includes(selectedBank?.bank || '') ||
          (selectedBank?.bank === 'altyn' &&
            (selectedBank?.statusType ===
              'financed_waiting_for_modification_before_pledge' ||
              selectedBank?.statusType === 'waiting_of_financing' ||
              selectedBank?.statusType === 'financed'))) && (
          <div className="info_ts" style={{ marginTop: '20px' }}>
            <div className="title">
              <h2>Данные ТС</h2>
              <p onClick={() => setShow(true)}>
                {selectedBank?.statusType === 'financed'
                  ? 'Подробнее'
                  : 'Добавить'}{' '}
                <img src={pathPenIcon} alt="pen" />
              </p>
            </div>

            <div className="ts_content">
              {['forte', 'bereke', 'halyk', 'eu_bank', 'altyn'].includes(
                selectedBank?.bank || ''
              ) && infoTS ? (
                <>
                  <p>{`VIN: ${values.vin}`}</p>
                  <h2>{values.licensePlate}</h2>
                </>
              ) : (
                <p className="noData">Нет данных</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
