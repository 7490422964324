import { TUserInfo, TUserResponse } from '@/types/user';

export const userInfoMapper = (userResponse: TUserResponse): TUserInfo => {
  const shouldAddMenuItems =
    userResponse.position.Positions_Modules.filter(
      (item) =>
        item.module.code === 'auto-credit' || item.module.code === 'insurances'
    ).length === 0;
  const userInfo: TUserInfo = {
    uuid: userResponse?.uuid || '',
    birth_date: userResponse?.birth_date.toString() || new Date().toISOString(),
    dealer: userResponse?.sale_point?.dealer?.name || '',
    email: userResponse?.email || '',
    first_name: userResponse?.first_name || '',
    middle_name: userResponse?.middle_name || '',
    last_name: userResponse?.last_name || '',
    is_active: userResponse?.is_active || false,
    login: userResponse?.login || '',
    frontendBlock: [
      ...(shouldAddMenuItems
        ? [
            ...userResponse.position.Positions_Modules.map(
              (block) => block.module
            ).filter(
              (item) => item.code !== 'autocred' && item.code !== 'insurance'
            ),
            {
              code: 'auto-credit',
              icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI5SURBVHgB1VW9ctNAEN6VM7ZnKDjTWB6KKDOJRemSLuYJyBvgks68QXiFPAHDE5A3iFJSQcnfTJQK0eCjYCYzwVq+u5PiSCcnFq74ZqTTnfb2dr/d2yX638FNi0pFivrdORPP6B6IiIaWj8TXr/X3i8v6/53GXf3eB5wc4SsRkpTuQsCKhWYi3Slme3QfVDh+MQhjMSNtCBXuH9o9w/i5d36D/BR+a519eUsbQmffzkETqJKj+j+fIuaJEDhtCVCZIGbT+vqNB2oUz1UYn4HPCcQTag1O8YqMDjU8mHsHEOUwnlSxfE5twZS4wSZHM4wXNlgmTVtCRZFygV5Zb1CLgSDACFa/9w6uUitclR82DiflrHNjQTg+RpBegqcMbmpzA9s+Vj3z0/6DR/rq98/3FQOK3H9DW8LoGAzHi3Jug6weH0zsTPiUtgYShFmp0d6umbkYLOWhHZl/2QMRbMRidWkkP9U/vp5ULF0r8+fCMi89UzYuXZoGHZc1y6V20rnHrY91MjtFwct3K9b8a3rWUU9X54GIVax1qmlL6DTVri6x1VnegwhP6lkTPsF6fgwSokZtphcE16+8PmDuUnGjrQfI/0gaDoDkGfL6aG3uozCSdBNvl/FAaOWBsGjOUaYr1u8fmroiws909imhBjiZTmJGW7JXHqiSIr4tWHiR2h+MqgpXF9nnO7vUYBQvxFFye59C+Z6ZnmIpMqdLgJxGby3dF9MuO34D8egIZAr5tLKPjNebN6yt8BfndMsxbNzcmQAAAABJRU5ErkJggg==',
              name: 'Автокредитование',
              uuid: 'c9e3b16f-245c-4fbe-9ab0-d38243e4e1c1',
              position: 2,
              is_active: true
            }
          ]
        : userResponse.position.Positions_Modules.map((block) => block.module)
      ).filter(
        (item) =>
          item.code !== 'leasing' &&
          item.code !== 'pos-cred' &&
          item.code !== 'insurances'
      )
    ],
    position: {
      uuid: userResponse?.position?.uuid || '',
      code: userResponse?.position?.code || 0,
      name: userResponse?.position?.name || '',
      is_active: userResponse?.position?.is_active || false
    },
    sale_point: userResponse?.sale_point?.name || '',
    user_iin: userResponse?.user_iin || '',
    phone_number: userResponse?.phone_number || '',
    work_date_start:
      userResponse?.work_date_start instanceof Date
        ? userResponse.work_date_start.toISOString()
        : new Date().toISOString()
  };

  return userInfo;
};
