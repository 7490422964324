import { TDataSource } from '../types/dataSource';
import { dispatch, useAppSelector } from '../store';
import {
  getRequestById,
  setClientInfo,
  setLoanData,
  setOrderApp,
  setRequestData
} from '../store/newOrder.slice';
import {
  getResponseByRequestId,
  resetReqSuccessIndicator
} from '../store/offerStep.slice';
import { onSetSelectedBank } from '../store/financingStep.slice';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { TClientInfoStore } from '../types/store';
import {
  onResetProductInfo,
  onSetProductInfo,
  onSetRequestId
} from '../store/orderStep.slice';
import {
  setVerifyModalIsNewApplication,
  setVerifyModalShowType
} from '../store/app.slice';
import { TNewOrderDto } from '@/types/newOrder';
import { mapperForLocalStorageRequest } from '@/services/mappers/mapperForLocaleStorageRequest';

const useRequestsChangeNavigation = () => {
  const { dicts } = useAppSelector((state) => state.dict);
  const { orderApp } = useAppSelector((state) => state.newOrder);
  const { banksOffers } = useAppSelector((state) => state.offerStep);

  const navigate = useNavigate();
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  const checkValidityOfData = useMemo(() => {
    const product = orderApp?.productInfo.product;
    return !!(product?.costCar && product.markModelYear && product.loanTerm);
  }, [orderApp?.productInfo.product]);

  const handleRequestDetails = async (req: TDataSource) => {
    dispatch(onResetProductInfo());

    if (req.status.isUnverified) {
      dispatch(setVerifyModalIsNewApplication(true));
      dispatch(onSetRequestId(req.id));
      dispatch(setVerifyModalShowType({ prevClient: false, smsVerf: true }));
      const clientInfo: TClientInfoStore = {
        id: req.orderType.code,
        fullName: req.client.fullName.split(' '),
        iin: req.client?.iin || '',
        telNum: req.client.telNumber
      };
      dispatch(setClientInfo(clientInfo));
      return;
    } else {
      if (req.status.isDraft) {
        const localRequest = localStorage.getItem(req.id);
        if (localRequest) {
          const parsedRequest: TNewOrderDto = JSON.parse(localRequest);
          const mappedReq = mapperForLocalStorageRequest(parsedRequest);

          console.log('localRequest ', JSON.parse(localRequest));
          dispatch(setRequestData(req.id));
          dispatch(onSetProductInfo(parsedRequest.productInfo.product));
          dispatch(setOrderApp(mappedReq));
          const clientInfo: TClientInfoStore = {
            id: req.orderType.code,
            fullName: req.client.fullName.split(' '),
            iin: req.client?.iin || '',
            telNum: req.client.telNumber
          };
          dispatch(setClientInfo(clientInfo));
          navigate(`/auto-credit/create?tab=1&step=1`);
          return;
        }
      }
      setIsRequestLoading(true);
      if (dicts) {
        dispatch(setRequestData(req.id));
        dispatch(setLoanData(req.loan));
        await dispatch(
          getRequestById({
            reqId: req.id,
            dicts: dicts
          })
        );
        await dispatch(getResponseByRequestId(req.id));
        const clientInfo: TClientInfoStore = {
          id: req.orderType.code,
          fullName: req.client.fullName.split(' '),
          iin: req.client?.iin || '',
          telNum: req.client.telNumber
        };
        dispatch(setClientInfo(clientInfo));
      }
      setIsRequestLoading(false);
    }
  };

  const changeData = () => {
    const financedIndex = banksOffers.findIndex(
      (offer) =>
        offer.statusType === 'financed' ||
        offer.statusType === 'waiting_of_financing' ||
        offer.statusType === 'on_rework' ||
        offer.statusType === 'financed_pledge_missing' ||
        offer.statusType === 'waiting_for_upload_files' ||
        offer.statusType ===
          'financed_waiting_for_modification_before_pledge' ||
        offer.statusType === 'mobile_client'
    );
    let currentHandler = 0;
    if (checkValidityOfData) {
      if (financedIndex !== -1) {
        dispatch(onSetSelectedBank(banksOffers[financedIndex]));
        currentHandler = 2;
      } else if (orderApp?.status !== 'draft') {
        currentHandler = 1;
      }
    }

    dispatch(resetReqSuccessIndicator());
    navigate(
      orderApp?.uuid && orderApp?.status !== 'draft'
        ? `/auto-credit/${orderApp.uuid}?tab=${currentHandler + 1}`
        : '/auto-credit/create?tab=1&step=1'
    );
  };

  return {
    isRequestLoading,
    handleRequestDetails,
    changeData
  };
};

export { useRequestsChangeNavigation };
